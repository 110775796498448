"use client";

import { createContext, useContext } from "react";
import { useCheckoutBusinessPricing } from "./CheckoutContext";

const ProductSelectionContext = createContext();

export function ProductSelectionProvider({ pricingData, children }) {
  return <ProductSelectionContext.Provider value={pricingData}>{children}</ProductSelectionContext.Provider>;
}

export const useProducts = () => useContext(ProductSelectionContext).products;
export const useCategories = () => useContext(ProductSelectionContext).categories;
export const useCheckoutProduct = (productId) => {
  const { categories, products } = useContext(ProductSelectionContext);

  if (Array.isArray(categories)) {
    for (const category of categories) {
      const product = category.products?.find((product) => product.key === productId);
      if (product) {
        return product;
      }
    }
  } else {
    return products?.find((product) => product.key === productId);
  }
};
export const useProductServiceTypes = (productId) => {
  const rawPricingData = useCheckoutBusinessPricing();
  const { serviceTypes } = useContext(ProductSelectionContext);
  const product = rawPricingData?.products?.find((product) => product.id === productId);

  return Array.isArray(product?.serviceTypes) && product?.serviceTypes.length > 0
    ? product?.serviceTypes
    : serviceTypes;
};
