"use client";

import { useDisplayName } from "@easybiz/context/src/NamingContext";

function OrderNoDisplay({ business, businessCode, orderNo, agent }) {
  const businessName = useDisplayName(businessCode || business?.id);

  if (agent) {
    return `#${orderNo} (${agent.name})`;
  } else {
    return `#${orderNo}${businessName ? ` (${businessName})` : ""}`;
  }
}

export default OrderNoDisplay;
