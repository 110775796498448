import { useErrorColor } from "@easybiz/context/src/ConfigurationContext";
import { usePrimaryColor } from "@easybiz/context/src/ThemingContext";
import { Avatar, Badge } from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import CustomerScreenSignatureDialog from "../CustomerScreenSignatureDialog";

export default function ({ signature, requireSignature, disabled, onSigned }) {
  const intl = useIntl();
  const primaryColor = usePrimaryColor();
  const errorColor = useErrorColor();
  const [open, setVisible] = useState(false);

  return (
    <Badge
      offset={signature || requireSignature ? [-8, -8] : null}
      status={signature ? "success" : "error"}
      color={signature ? primaryColor : requireSignature ? errorColor : undefined}
      dot={signature || requireSignature ? false : true}
      count={
        signature
          ? intl.formatMessage({ defaultMessage: "Signed" })
          : requireSignature
          ? intl.formatMessage({ defaultMessage: "Not signed" })
          : null
      }
    >
      <Avatar
        className={disabled ? null : "selectable"}
        size="small"
        shape="square"
        src={signature}
        onClick={disabled ? null : () => setVisible(true)}
      />
      <CustomerScreenSignatureDialog
        open={open}
        onCancel={() => setVisible(false)}
        onSigned={(signature) => {
          setVisible(false);
          onSigned(signature);
        }}
      />
    </Badge>
  );
}
