import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ADJUSTMENT_MODE_DISCOUNT, PROMO_TYPE_FLAT, PROMO_TYPE_PERCENT } from "@easybiz/utils";
import { Button } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PricingOverviewItemBase from "../PricingOverviewItemBase";
import QtyInputPopover from "../QtyInputPopover";
import { useCurrency } from "@easybiz/context/src/ConfigurationContext";

export default ({ adjustment, onChange, disabled }) => {
  const currency = useCurrency();
  const { title, mode, value, type, lineTotal, editable } = adjustment;
  const [open, setVisible] = useState(false);
  const isDiscount = mode === ADJUSTMENT_MODE_DISCOUNT;

  return (
    <PricingOverviewItemBase
      title={title}
      isDiscount={isDiscount}
      type={isDiscount ? "success" : null}
      amount={lineTotal}
      extra={
        onChange &&
        (editable ? (
          <QtyInputPopover
            removeable
            open={open}
            onOpenChange={setVisible}
            title={title}
            decimal
            placement={"left"}
            value={value}
            onDone={(update) => {
              setVisible(false);
              onChange({ ...adjustment, value: update });
            }}
            suffix={type === PROMO_TYPE_PERCENT ? "%" : null}
            prefix={type === PROMO_TYPE_FLAT ? currency : null}
          >
            <Button size="small" type="link" icon={<EditOutlined />} disabled={disabled}>
              <FormattedMessage tagName={"span"} defaultMessage={"Edit"} />
            </Button>
          </QtyInputPopover>
        ) : (
          <Button
            size="small"
            type="text"
            disabled={disabled}
            danger
            icon={<DeleteOutlined />}
            onClick={() => onChange({ ...adjustment, value: null })}
          >
            <FormattedMessage tagName={"span"} defaultMessage={"Remove"} />
          </Button>
        ))
      }
    />
  );
};
