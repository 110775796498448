import { useCheckoutService, useCheckoutEditOrder, useSetCheckoutService } from "@easybiz/context/src/CheckoutContext";
import { useImageHost } from "@easybiz/context/src/ConfigurationContext";
import { useBusinesses } from "@easybiz/context/src/RealmContext";
import { Title } from "@easybiz/web-admin";
import { Button, Checkbox, List, message, Popover } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default () => {
  const services = useBusinesses();
  const select = useCheckoutService();
  const setService = useSetCheckoutService();
  const imageHost = useImageHost();
  const eidtOrder = useCheckoutEditOrder();
  const [open, setVisible] = useState(false);
  const intl = useIntl();

  return (
    <Popover
      open={open}
      onOpenChange={(open) => {
        if (!eidtOrder) {
          setVisible(open);
        } else {
          message.info(
            intl.formatMessage({
              defaultMessage: "You can not switch business during order editing",
            })
          );
        }
      }}
      title={
        <Title>
          <FormattedMessage defaultMessage={"Switch Business"} />
        </Title>
      }
      overlayStyle={{ width: 350 }}
      content={
        <List
          dataSource={services || []}
          renderItem={(service) => {
            return (
              <List.Item
                className="selectable"
                onClick={() => {
                  setService(service);
                  setVisible(false);
                }}
                extra={<Checkbox checked={select?.id === service.id} />}
              >
                <List.Item.Meta
                  avatar={service.photoId ? <Avatar src={`${imageHost}/${service.photoId}`} shape="square" /> : null}
                  title={<Title ellipsis>{service.title}</Title>}
                />
              </List.Item>
            );
          }}
        />
      }
      trigger="click"
    >
      <Button size="large" disabled={Boolean(eidtOrder)}>
        <Avatar shape="square" src={select?.photoId && `${imageHost}/${select.photoId}`} size={28}>
          {`${select?.title || ""}`.slice(0, 2)}
        </Avatar>
      </Button>
    </Popover>
  );
};
