"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { collection, getFirestore, onSnapshot, orderBy, query } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (taskId) => {
  const realmId = useRealmId();
  const [operations, setOperations] = useState();

  useEffect(() => {
    setOperations(null);
    if (realmId && taskId) {
      const handler = onSnapshot(
        query(collection(getFirestore(), `realms/${realmId}/tasks/${taskId}/histories`), orderBy("createDate", "desc")),
        (result) => setOperations(result.docs)
      );
      return handler;
    }
  }, [realmId, taskId]);

  return operations;
};
