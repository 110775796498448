import { PhotoSwipeGallery } from "@easybiz/web-component";
import { Space } from "antd";
import React from "react";
import DeletePhotoButton from "../DeletePhotoButton";
import { useGallery, useOpenGallery } from "@easybiz/context/src/PhotoGalleryContext";

export default function () {
  const gallery = useGallery();
  const setGallery = useOpenGallery();
  const { photos, viewIndex, orderId } = gallery || {};

  return gallery ? (
    <PhotoSwipeGallery
      photos={photos}
      viewIndex={viewIndex}
      onRenderCaption={(currentImage) => (
        <Space>
          {photos[currentImage]?.title}
          {orderId && photos[currentImage]?.id && (
            <DeletePhotoButton
              orderId={orderId}
              photoId={photos[currentImage]?.id}
              onDeleted={(photoId) => {
                const photos = (photos || []).filter((photo) => photo.id !== photoId);

                if (photos.length > 0) {
                  setGallery({ ...gallery, photos });
                } else {
                  setGallery(null);
                }
              }}
            />
          )}
        </Space>
      )}
      onClose={() => setGallery(null)}
    />
  ) : null;
}
