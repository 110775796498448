"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { collection, getFirestore, onSnapshot, orderBy, query } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (enabled) => {
  const realmId = useRealmId();
  const [items, setItems] = useState();

  useEffect(() => {
    if (realmId && enabled) {
      return onSnapshot(
        query(collection(getFirestore(), `realms/${realmId}/recyclables`), orderBy("sortIndex")),
        (result) => setItems(result.docs)
      );
    }
  }, [realmId, enabled]);

  return items;
};
