import { useCheckoutParams, useCheckoutService } from "@easybiz/context/src/CheckoutContext";
import { useOrderRedo } from "@easybiz/api-order";
import { ORDER_SOURCE_POS, processOrderPricing, UNIT_PCS } from "@easybiz/utils";
import { OrderPricingList } from "@easybiz/web-admin";
import { collection, doc, getFirestore } from "@firebase/firestore";
import { Alert, Button, Card, Col, Row } from "antd";
import { useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import CheckoutCollectionScheduleButton from "./CheckoutCollectionScheduleButton";
import PanelHeader from "./PanelHeader";
import { useDisplayMode, useSetDisplayMode, useSetOpenOrderId } from "@easybiz/context/src/LiveStateContext";

export default function RedoOrderOverviewPanel() {
  const displayMode = useDisplayMode();
  const setDisplayMode = useSetDisplayMode();
  const { order, selects, returnSchedule, address, collectionPlace, scheduleOpen } = displayMode;
  const service = useCheckoutService();
  const checkoutParams = useCheckoutParams();
  const setOpenOrderId = useSetOpenOrderId();
  const { onSubmit, loading } = useOrderRedo((succeed, response) => {
    if (succeed) {
      setOpenOrderId(response.newOrder?.id);
      setDisplayMode(null);
    }
  });

  useEffect(() => {
    if (!returnSchedule && order.get("service.id") === service?.id && checkoutParams?.returnSchedule) {
      setDisplayMode({ ...displayMode, returnSchedule: checkoutParams?.returnSchedule });
    }
  }, []);

  const { redoItems, redoPricing } = useMemo(() => {
    const redoItems = [].concat.apply(
      [],
      (order.get("items") || []).map(({ labelIndexes, ...item }) => {
        const itemSelects =
          Array.isArray(selects) && Array.isArray(labelIndexes)
            ? selects.filter(({ index }) => labelIndexes.indexOf(index) >= 0)
            : [];

        let noteMap = {};

        itemSelects.forEach(({ index, note }) => {
          noteMap[note || ""] = (noteMap[note || ""] || 0) + 1;
        });

        return Object.keys(noteMap).map((note) => {
          return {
            ...item,
            returnLabels: itemSelects.map(({ index }) => index),
            note,
            qty: noteMap[note],
            unitPrice: 0,
            unit: UNIT_PCS,
          };
        });
      })
    );

    return {
      redoItems,
      redoPricing: processOrderPricing({
        type: order?.get("type"),
        items: redoItems,
        service: order?.get("service"),
      }),
    };
  }, [selects, order]);

  return (
    <div className="flex flex-fill scroll-container">
      <PanelHeader
        title={
          <FormattedMessage
            defaultMessage={"Redo Order {orderNo}"}
            values={{
              orderNo: `#${order.get("orderNo")}`,
            }}
          />
        }
        onClose={() => setDisplayMode(null)}
        noPadding
      />
      <Card
        className="flex flex-fill scroll-container margin-top-half"
        bodyStyle={{ display: "flex", flexDirection: "column", padding: 0, height: "100%" }}
      >
        <div className="flex-fill scroll-body padding-half">
          <Alert
            type="info"
            message={<FormattedMessage tagName={"b"} defaultMessage={"You are creating free of charge redo order"} />}
          />
          <OrderPricingList pricing={redoPricing} />
        </div>
        <Row gutter={[8, 8]} className="padding-half">
          <Col span={24}>
            <CheckoutCollectionScheduleButton
              address={address}
              returnSchedule={returnSchedule}
              collectionPlace={collectionPlace}
              selected={scheduleOpen}
              onSelect={() => setDisplayMode({ ...displayMode, scheduleOpen: !scheduleOpen })}
            />
          </Col>
          <Col span={24}>
            <Button
              loading={loading}
              type={"primary"}
              block
              onClick={() => {
                onSubmit({
                  orderId: doc(collection(getFirestore(), `orders`)).id,
                  redoOrderId: order.id,
                  source: ORDER_SOURCE_POS,
                  checkoutParams: {
                    items: redoItems,
                    returnSchedule,
                    address,
                  },
                });
              }}
            >
              <FormattedMessage defaultMessage={"Create redo order"} />
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
