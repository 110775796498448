"use client";

import { Component } from "react";
import { FormattedMessage } from "react-intl";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            role="img"
            aria-label="close-circle"
            style={{
              fontSize: this.props.fontSize || 72,
              color: this.props.color || "#ff4d4f",
            }}
          >
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="close-circle"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
            </svg>
          </span>
          <h2 style={{ textAlign: "center" }}>
            {this.props.title || (
              <FormattedMessage id="oops.something.went.wrong" defaultMessage={"Oops! Something went wrong"} />
            )}
          </h2>
          {this.props.onReport ? (
            this.props.onReport(this.state.error)
          ) : (
            <p style={{ textAlign: "center", color: "#00000073" }}>{String(this.state.error.message)}</p>
          )}
          {this.props.reloadButton || <button onClick={() => window.location.reload(true)}>Reload App</button>}
          <br />
          <br />
          <br />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
