"use client";

import dayjs from "dayjs";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useBusinessCode } from "./ConfigurationContext";

const LiveStateContext = createContext();

export function LiveStateProvider({ children }) {
  const [displayMode, setDisplayMode] = useState(null);
  const [openConversation, setOpenConversation] = useState();
  const [openOrderId, setOpenOrderId] = useState();
  const [visitingStore, setVisitingStore] = useState(); // For handover
  const [collectionOptions, setCollectionOptions] = useState();
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));

  useEffect(() => {
    const handler = setInterval(() => {
      if (dayjs().format("YYYY-MM-DD") !== date) {
        setDate(dayjs().format("YYYY-MM-DD"));
      }
    }, 5 * 60 * 1000);

    return () => clearInterval(handler);
  }, [date]);

  const businessCode = useBusinessCode();

  const context = useMemo(() => {
    return {
      displayMode,
      visitingStore: visitingStore || businessCode,
      openOrderId,
      setOpenOrderId,
      openConversation,
      setOpenConversation,
      collectionOptions,
      date,
      setDisplayMode,
      setVisitingStore,
      setCollectionOptions
    };
  }, [
    openOrderId,
    openConversation,
    visitingStore,
    businessCode,
    collectionOptions,
    displayMode,
    date,
  ]);

  return <LiveStateContext.Provider value={context}>{children}</LiveStateContext.Provider>;
}

export const useClientDate = () => useContext(LiveStateContext).date;
export const useDisplayMode = () => useContext(LiveStateContext).displayMode;
export const useSetDisplayMode = () => useContext(LiveStateContext).setDisplayMode;
export const useOpenOrderId = () => useContext(LiveStateContext).openOrderId;
export const useSetOpenOrderId = () => useContext(LiveStateContext).setOpenOrderId;
export const useOpenConversation = () => useContext(LiveStateContext).openConversation;
export const useSetOpenConversation = () => useContext(LiveStateContext).setOpenConversation;
export const useCollectionOptions = () => useContext(LiveStateContext).collectionOptions;
export const useVisitingStore = () => useContext(LiveStateContext).visitingStore;
export const useSetVisitingStore = () => useContext(LiveStateContext).setVisitingStore;
export const useSetCollectionOptions = () => useContext(LiveStateContext).setCollectionOptions;
