import { CheckOutlined } from "@ant-design/icons";
import { OrderNoDisplay } from "@easybiz/component";
import { useSetOpenOrderId } from "@easybiz/context/src/LiveStateContext";
import { useToDisplayName } from "@easybiz/context/src/NamingContext";
import { OrderDischargePanel, Title } from "@easybiz/web-admin";
import { useOrderDoc } from "@easybiz/web-firebase";
import { Button, List, Menu, Space, Tag } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

export default ({ height, businessCode, statusDoc }) => {
  const [selectOrderId, setSelectOrderId] = useState();
  const selectOrder = useOrderDoc(selectOrderId);
  const setOpenOrderId = useSetOpenOrderId();
  const toDisplayName = useToDisplayName();

  const orders = useMemo(() => {
    if (statusDoc) {
      let pendings = [],
        packed = [],
        completes = [];

      const data = statusDoc.get("data") || {};
      for (const orderId in data) {
        if (data[orderId].b === businessCode) {
          if (data[orderId].w === 0) {
            completes.push({ ...data[orderId], id: orderId });
          } else {
            if (Array.isArray(data[orderId].s)) {
              packed.push({ ...data[orderId], id: orderId });
            } else {
              pendings.push({ ...data[orderId], id: orderId });
            }
          }
        }
      }

      return [
        ...packed.sort((a, b) => {
          const result = `${a.s
            .map(({ id }) => id || "")
            .sort()
            .join("")}`.localeCompare(
            b.s
              .map(({ id }) => id || "")
              .sort()
              .join("")
          );
          if (result === 0) {
            return `${a.no}`.localeCompare(b.no);
          } else {
            return result;
          }
        }),
        ...pendings.sort((a, b) => `${a.no}`.localeCompare(b.no)),
        ...completes.sort((a, b) => `${a.no}`.localeCompare(b.no)),
      ];
    }
  }, [businessCode, statusDoc]);

  useEffect(() => {
    if (Array.isArray(orders) && orders[0] && (!selectOrderId || !orders.find((order) => order.id === selectOrderId))) {
      setSelectOrderId(orders[0].id);
    }
  }, [orders]);

  return (
    <div className="flex flex-fill flex-row scroll-container">
      <div style={{ width: 190 }} className="flex scroll-body">
        <Menu
          selectedKeys={selectOrderId ? [selectOrderId] : []}
          mode="inline"
          onSelect={({ key }) => setSelectOrderId(key)}
          items={(orders || []).map((order) => {
            const { s, w } = order;
            const completed = w === 0;
            return {
              key: order.id,
              label: (
                <Space>
                  <b>#{order.no}</b>
                  {completed ? (
                    <Tag color="success" icon={<CheckOutlined />}>
                      <FormattedMessage defaultMessage={"Collected"} />
                    </Tag>
                  ) : Array.isArray(s) ? (
                    s.map(({ q, id }) => {
                      const [code, storageId] = `${id}`.includes(`::`) ? `${id}`.split(`::`) : [id];

                      return (
                        <Tag key={id || "unknow"} color={id ? "processing" : null}>
                          {q}){[code ? `${toDisplayName(code) || code}` : null, storageId].filter(Boolean).join(`-`)}
                        </Tag>
                      );
                    })
                  ) : null}
                </Space>
              ),
            };
          })}
        ></Menu>
      </div>
      <div className="flex flex-fill scroll-body padding-horizontal-half">
        {selectOrderId ? (
          <>
            <div className="flex flex-row flex-align-center flex-space-between padding-bottom-half">
              {selectOrder ? (
                <Title>
                  <OrderNoDisplay {...selectOrder.data()} />
                </Title>
              ) : (
                <span />
              )}
              <Button size="large" onClick={() => setOpenOrderId(selectOrderId)}>
                <FormattedMessage tagName="span" defaultMessage="Order detail" />
              </Button>
            </div>
            <OrderDischargePanel order={selectOrder} />
          </>
        ) : (
          <List loading={!statusDoc} />
        )}
        <br />
      </div>
    </div>
  );
};
