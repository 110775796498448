import { useToast } from "@easybiz/context/src/ConfigurationContext";
import { useActionCallback } from "@easybiz/hook";
import { ADMIN_ACTION_PRINTER_REGISTER, OPERATION_GROUP_ADMIN } from "@easybiz/utils";
import { useIntl } from "react-intl";

function useAdminPrinterRegister(callback) {
  const intl = useIntl();
  const toast = useToast();

  return useActionCallback(
    ({ settings, printerType, printer, io, deleted }) => {
      if (!printer) {
        return intl.formatMessage({ defaultMessage: "Please select printer" });
      }

      return [
        OPERATION_GROUP_ADMIN,
        ADMIN_ACTION_PRINTER_REGISTER,
        {
          settings,
          printerType,
          printer: {
            io: io || printer.io,
            address: printer.address || printer.id,
            serialNumber: printer.address || printer.id || printer.serialNumber,
            vendorId: printer.vendorId,
            productId: printer.productId,
            productName: printer.productName || printer.name,
            manufacturerName: printer.manufacturerName,
          },
          ...(deleted && { deleted }),
        },
      ];
    },
    callback,
    (response, request) =>
      toast.success(
        request.deleted
          ? intl.formatMessage({ defaultMessage: "Printer setting removed" })
          : intl.formatMessage({ defaultMessage: "Printer setting updated" })
      )
  );
}

export default useAdminPrinterRegister;
