"use client";

import { useAdminIntercomBoot } from "@easybiz/api-admin";
import { useIsPlatformMode } from "@easybiz/context/src/AuthenticationContext";
import { useEffect } from "react";

function IntercomLauncher({ onLaunched }) {
  const platformMode = useIsPlatformMode();

  const { onSubmit } = useAdminIntercomBoot((succeed, response) => {
    if (succeed) {
      onLaunched(response.intercom);
    }
  });

  useEffect(() => {
    if (onSubmit && !platformMode) {
      onSubmit();
    }
  }, [platformMode, Boolean(onSubmit)]);

  return null;
}

export default IntercomLauncher;
