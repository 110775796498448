import { PlusOutlined } from "@ant-design/icons";
import { useCheckoutService } from "@easybiz/context/src/CheckoutContext";
import { translate, UNIT_PCS } from "@easybiz/utils";
import { useServiceAccessoryQuery } from "@easybiz/web-firebase";
import { Input, List, message } from "antd";
import React, { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../IconButton";
import QuantityUpdateBar from "../QuantityUpdateBar";

export default ({ style, selects, onChange }) => {
  const service = useCheckoutService();
  const accessories = useServiceAccessoryQuery(service?.id, true);
  const [otherName, setOtherName] = useState("");
  const intl = useIntl();

  const onAddOther = useCallback(
    (e) => {
      if (typeof e?.stopPropagation === "function") {
        e.stopPropagation();
      }

      if (!otherName)
        return message.info(
          intl.formatMessage({
            defaultMessage: `Please enter accessory name`,
          })
        );

      const otherIndex = selects ? selects.findIndex((select) => select.id === otherName) : -1;

      onChange(
        otherIndex >= 0
          ? [
              ...selects.slice(0, otherIndex),
              {
                ...selects[otherIndex],
                qty: selects[otherIndex].qty + 1,
              },
              ...selects.slice(otherIndex + 1),
            ]
          : [...(selects || []), { id: otherName, title: otherName, qty: 1 }]
      );
      setOtherName("");
    },
    [otherName, selects, onChange]
  );

  return (
    <List
      style={style}
      size="small"
      loading={!accessories}
      dataSource={[
        ...(accessories || []),
        ...(selects || []).filter(
          (select) => !accessories || !accessories.find((accessory) => accessory.id === select.id)
        ),
        true,
      ]}
      renderItem={(accessory) => {
        if (accessory === true) {
          // Footer
          return (
            <List.Item extra={<IconButton icon={<PlusOutlined />} onClick={onAddOther} />}>
              <List.Item.Meta
                title={
                  <div className="padding-right-half">
                    <Input
                      placeholder={intl.formatMessage({ defaultMessage: "Other" })}
                      value={otherName}
                      onChange={(e) => setOtherName(e.target.value)}
                      onSubmit={onAddOther}
                    />
                  </div>
                }
              />
            </List.Item>
          );
        } else {
          const selected = selects?.find((other) => other.id === accessory.id);

          return (
            <List.Item
              extra={
                <QuantityUpdateBar
                  title={<FormattedMessage defaultMessage={"Accessory Quantity"} />}
                  value={selected ? selected.qty : 0}
                  suffix={UNIT_PCS}
                  onChange={(newValue, e) => {
                    if (typeof e?.stopPropagation === "function") {
                      e.stopPropagation();
                    }

                    const index = (selects || []).findIndex((other) => other.id === accessory.id);
                    let newSelects;
                    if (index >= 0 && newValue && newValue > 0) {
                      // Update
                      newSelects = [
                        ...selects.slice(0, index),
                        { ...selects[index], qty: newValue },
                        ...selects.slice(index + 1),
                      ];
                    } else if (index >= 0 && !newValue) {
                      // Remove
                      newSelects = [...selects.slice(0, index), ...selects.slice(index + 1)];
                    } else if (index <= 0 && newValue && newValue > 0) {
                      // Add
                      newSelects = [
                        ...(selects || []),
                        {
                          ...accessory,
                          qty: newValue,
                        },
                      ];
                    }

                    onChange(newSelects);
                  }}
                />
              }
            >
              <List.Item.Meta title={translate(accessory.title)} />
            </List.Item>
          );
        }
      }}
    />
  );
};
