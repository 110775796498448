"use client";

import { OPERATION_GROUP_PLATFORM, PLATFORM_ACTION_ERROR_REPORT } from "@easybiz/utils";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

function useErrorReport({ httpsFunction, realmId, error, client }) {
  const [errorNo, setErrorNo] = useState();

  useEffect(() => {
    if (error) {
      httpsFunction({
        group: OPERATION_GROUP_PLATFORM,
        type: PLATFORM_ACTION_ERROR_REPORT,
        client,
        data: {
          error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        },
      })
        .then((response) => setErrorNo(response.data?.errorNo))
        .catch(() => {});
    }
  }, [realmId, error]);

  return useMemo(() => {
    if (errorNo) {
      return {
        title: <FormattedMessage id="error.code.x" defaultMessage="Error Code: {code}" values={{ code: errorNo }} />,
        description: (
          <FormattedMessage
            id="error.reported"
            defaultMessage={
              "We track these errors automatically, our team will get it fixed ASAP. In the meantime, try refresh or try again later."
            }
          />
        ),
      };
    }
  }, [errorNo]);
}

export default useErrorReport;
