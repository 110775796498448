import { usePOSCloseOfDayPreview, usePOSCloseOfDaySubmit } from "@easybiz/api-pos";
import { DateDisplay } from "@easybiz/component";
import { useBusinessCode } from "@easybiz/context/src/ConfigurationContext";
import { ERROR_CODE_CLOSE_OF_DAY_DATA_CHANGED } from "@easybiz/utils";
import { Dialog, ReceiptView, SignaturePad } from "@easybiz/web-admin";
import { Form, Input, Space } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CashDrawerEndAlert from "./CashDrawerEndAlert";
import { useRealmId } from "@easybiz/context/src/ConfigurationContext";

export default ({ open, onCancel, onComplete, dateKey }) => {
  const relamId = useRealmId();
  const [signature, setSignature] = useState();
  const [note, setNote] = useState("");
  const intl = useIntl();
  const businessCode = useBusinessCode();
  const { onSubmit: onPreview, response: preview } = usePOSCloseOfDayPreview();
  const { onSubmit, loading: submitting } = usePOSCloseOfDaySubmit((succeed, result) => {
    if (succeed) {
      onComplete();
    } else if (result?.errorCode === ERROR_CODE_CLOSE_OF_DAY_DATA_CHANGED) {
      onPreview({ date: dateKey, businessCode });
    }
  });

  useEffect(() => {
    if (open) {
      setNote("");
      setSignature(null);
    }
  }, [open]);

  useEffect(() => {
    if (relamId && open) {
      onPreview({ date: dateKey, businessCode });
    }
  }, [open, relamId]);

  return (
    <Dialog
      open={open}
      onCancel={onCancel}
      confirmLoading={submitting}
      title={
        <FormattedMessage
          tagName="span"
          defaultMessage="Close of Day Report ({date})"
          values={{
            date: <DateDisplay date={dateKey} />,
          }}
        />
      }
      width={window.innerWidth - 32}
      okText={<FormattedMessage tagName="span" defaultMessage="Run close of day" />}
      onOk={() => onSubmit({ signature, note, preview })}
      bodyStyle={{ padding: 16 }}
    >
      <div className="flex flex-row">
        <div style={{ height: window.innerHeight - 200, overflowY: "auto" }}>
          <ReceiptView lines={preview?.lines} loading={!preview} />
        </div>
        <div className="flex flex-fill padding-left" style={{ height: window.innerHeight - 200, overflowY: "auto" }}>
          <Space direction="vertical" className="full-width">
            {dateKey === dayjs().format("YYYY-MM-DD") && <CashDrawerEndAlert />}
            <Form layout="vertical">
              <Form.Item label={<FormattedMessage defaultMessage={"Close of day note"} />}>
                <Input.TextArea
                  size="large"
                  value={note}
                  disabled={submitting}
                  placeholder={intl.formatMessage({ defaultMessage: "Note" })}
                  onChange={(e) => setNote(e.target.value)}
                />
              </Form.Item>
              <Form.Item label={<FormattedMessage defaultMessage={"Signature"} />}>
                <SignaturePad onChange={setSignature} disabled={submitting} />
              </Form.Item>
            </Form>
          </Space>
        </div>
      </div>
    </Dialog>
  );
};
