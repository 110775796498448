import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { OrderNoDisplay } from "@easybiz/component";
import { useBusinessCode } from "@easybiz/context/src/ConfigurationContext";
import { Badge, Button, Space } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

export default ({ date, filter, orders, complete, onSelect }) => {
  const businessCode = useBusinessCode();
  const [hideComplete, setHideComplete] = useState(complete > 10);

  useEffect(() => {
    if (date) {
      setHideComplete(complete > 10);
    }
  }, [date, filter]);

  return (
    <Space wrap>
      {(orders || []).map((order) => {
        if (hideComplete && order.completed) return null;

        return (
          <Badge
            key={`${order.id}${order.name}`}
            status={order.completed ? "success" : "warning"}
            count={
              order.name
                ? `#${order.orderNo}`
                : order.total
                ? `${order.complete}/${order.total}`
                : `#${order.index + 1}`
            }
          >
            <Button type={order.completed ? "link" : "default"} onClick={() => onSelect(order)}>
              {order.name ? (
                `${order.name}${order.total ? `(${order.complete}/${order.total})` : ""}`
              ) : businessCode === order.businessCode ? (
                `#${order.orderNo}`
              ) : (
                <OrderNoDisplay orderNo={order.orderNo} businessCode={order.businessCode} />
              )}
            </Button>
          </Badge>
        );
      })}
      {complete > 10 && (
        <Button
          type="text"
          icon={hideComplete ? <DownOutlined /> : <UpOutlined />}
          onClick={() => setHideComplete(!hideComplete)}
        >
          {hideComplete ? (
            <FormattedMessage tagName={"span"} defaultMessage={"Show completed"} />
          ) : (
            <FormattedMessage tagName={"span"} defaultMessage={"Hide completed"} />
          )}
          ({complete})
        </Button>
      )}
    </Space>
  );
};
