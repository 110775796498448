import { useDisplayMode, useSetDisplayMode } from "@easybiz/context/src/LiveStateContext";
import { IconButton } from "@easybiz/web-admin";
import dayjs from "dayjs";

export default function ({ icon, targetMode }) {
  const displayMode = useDisplayMode();
  const setDisplayMode = useSetDisplayMode();
  const selected = displayMode?.key === targetMode;

  return (
    <IconButton
      type={selected ? "primary" : "default"}
      onClick={() => setDisplayMode(selected ? null : { key: targetMode, date: dayjs() })}
      icon={icon}
    />
  );
}
