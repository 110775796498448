import { CloseOutlined, CopyOutlined, RightOutlined } from "@ant-design/icons";
import { useSetCheckoutEditItem } from "@easybiz/context/src/CheckoutContext";
import { useBagAlterProductSelectPanel } from "@easybiz/pos";
import { Badge, Button, Divider, Menu, Space } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import IconButton from "../IconButton";
import ProductSelectStep from "../ProductSelectStep";
import PopConfirm from "../PopConfirm";
import PriceEditDialog from "../PriceEditDialog";
import RemoveLineItemButton from "../RemoveLineItemButton";
import Title from "../Title";

export default function ({ item, initStepId }) {
  const setEditItem = useSetCheckoutEditItem();
  const intl = useIntl();
  const {
    title,
    amountDisplay,
    isEditing,
    copyTitle,
    nextStepTitle,
    steps,
    activeKey,
    activeStep,
    stepValue,
    nextStep,
    nextHeighLight,
    itemTotalVisible,
    onGoToStep,
    onStepChange,
    onStepValueChange,
    onCopy,
    onNext,
    onCancelPrice,
    onSetPrice,
  } = useBagAlterProductSelectPanel(item, initStepId);

  return (
    <div className="flex flex-fill scroll-container">
      <div className="flex flex-row flex-space-between flex-align-center padding-horizontal-half">
        <Space>
          <Title>{title}</Title>
          {isEditing && <RemoveLineItemButton item={item} />}
        </Space>
        <Space>
          <Title>{amountDisplay}</Title>
          <IconButton icon={<CloseOutlined />} onClick={() => setEditItem(null)} />
        </Space>
      </div>
      <Divider style={{ margin: 8 }} />
      <div className="flex flex-fill flex-row scroll-container">
        <div className="flex" style={{ width: 140, maxWidth: 140 }}>
          <div className="flex flex-fill self-scroll">
            <Menu
              mode="inline"
              items={[
                {
                  type: "group",
                  label: <FormattedMessage defaultMessage={"Line item details"} />,
                  children: steps.filter((step) => step.detail),
                },
                {
                  type: "group",
                  label: <FormattedMessage defaultMessage={"Other"} />,
                  children: steps.filter((step) => !step.detail),
                },
              ]}
              selectedKeys={[activeKey]}
              onSelect={({ key }) => onGoToStep(key)}
            />
            <br />
            <br />
            <br />
          </div>
          {isEditing && (
            <div className="padding-horizontal-half">
              <PopConfirm
                title={copyTitle}
                onConfirm={onCopy}
                okText={<FormattedMessage tagName={"span"} defaultMessage="Copy & Add" />}
              >
                <Button type="dashed" icon={<CopyOutlined />} block>
                  <FormattedMessage tagName={"span"} defaultMessage="Copy" />
                </Button>
              </PopConfirm>
            </div>
          )}
        </div>
        <div className="flex flex-fill scroll-container">
          {activeStep && (
            <div className="flex flex-row flex-space-between flex-align-center padding-horizontal-half">
              <Space>
                <Title>{activeStep.label}</Title>
                {activeStep.mandatory && <Badge count={intl.formatMessage({ defaultMessage: "Required" })} />}
              </Space>
              {activeStep?.detail ? (
                <Button type={nextHeighLight ? "primary" : "default"} onClick={onNext}>
                  {nextStepTitle}
                  {nextStep && <RightOutlined />}
                </Button>
              ) : null}
            </div>
          )}
          {activeStep && <Divider style={{ margin: "8px 0px 8px 0px" }} />}
          <div className="flex flex-fill self-scroll">
            {activeStep && (
              <ProductSelectStep
                {...item}
                step={activeStep}
                stepValue={stepValue}
                onChange={onStepChange}
                onValueChange={onStepValueChange}
              />
            )}
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
      <PriceEditDialog
        initPrice={item?.price}
        title={<FormattedMessage defaultMessage="Item Total" />}
        open={itemTotalVisible}
        onCancel={onCancelPrice}
        onChange={onSetPrice}
      />
    </div>
  );
}
