"use client";

import { useOrderDischarge } from "@easybiz/api-order";
import { useBusinessCode, useCustomerScreen, useToast } from "@easybiz/context/src/ConfigurationContext";
import { usePOSSettings } from "@easybiz/context/src/RealmContext";
import { CUSTOMER_SCREEN_COLLECTION, UNIT_PCS } from "@easybiz/utils";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

function useOrderDischargePanel(order, corporate) {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [selects, setSelects] = useState([]);
  const [signature, setSignature] = useState();
  const businessCode = useBusinessCode();
  const customerScreen = useCustomerScreen();
  const { enforceScanToDischarge, paidOnlyDischarge } = usePOSSettings();
  const toast = useToast();
  const intl = useIntl();

  const { onSubmit, loading, response, onClear } = useOrderDischarge((success) => {
    if (success) {
      setSelects([]);
      setConfirmVisible(false);
    }
  });

  useEffect(() => {
    if (customerScreen?.exists()) {
      setSignature(customerScreen.get("signature"));
    }
  }, [customerScreen?.get("signature")]);

  const customerScreenMode = useMemo(() => {
    if (customerScreen?.get("linking")) {
      if (confirmVisible) {
        const collecedItems = (order?.get("items") || [])
          .map((item) => {
            const { labelIndexes } = item;
            const selectLabels = Array.isArray(labelIndexes)
              ? labelIndexes.filter((index) => Array.isArray(selects) && selects.indexOf(index) >= 0)
              : [];

            if (selectLabels.length > 0) {
              return {
                ...item,
                qty: selectLabels.length,
                unit: UNIT_PCS,
                labelIndexes: selectLabels,
                allCollceted: selectLabels.length === labelIndexes.length,
              };
            }
          })
          .filter((item) => item);

        return {
          mode: CUSTOMER_SCREEN_COLLECTION,
          items: collecedItems,
        };
      } else if (customerScreen.get("mode") === CUSTOMER_SCREEN_COLLECTION) {
        return { linking: customerScreen.get("linking") };
      }
    }
  }, [confirmVisible, customerScreen?.get("linking")]);

  const { itemsStatus, items, procedures, totalBalance } = order?.data() || {};

  let pending = [],
    complete = [];

  items?.forEach(({ labelIndexes, ...item }, itemIndex) => {
    const completeIndexes = labelIndexes?.filter(
      (sortIndex) => (itemsStatus && itemsStatus[sortIndex]) === procedures?.length - 1
    );
    const pendingIndexes = labelIndexes?.filter(
      (sortIndex) => (itemsStatus && itemsStatus[sortIndex]) !== procedures?.length - 1
    );

    if (pendingIndexes?.length > 0) {
      pending.push({ ...item, labelIndexes: pendingIndexes, itemIndex });
    }

    if (completeIndexes?.length > 0) {
      complete.push({ ...item, labelIndexes: completeIndexes, itemIndex });
    }
  });

  let pendingIndexes, completeIndexes;

  if (pending.length > 0) {
    pendingIndexes = [].concat.apply(
      [],
      pending.map(({ labelIndexes }) => labelIndexes || [])
    );
  }

  if (complete.length > 0) {
    completeIndexes = [].concat.apply(
      [],
      complete.map(({ labelIndexes }) => labelIndexes || [])
    );
  }

  return {
    customerScreenMode,
    onSubmit: () =>
      onSubmit({
        orderId: order.id,
        labelIds: (selects || []).map((sortIndex) => order.get("labels")[sortIndex - 1]).filter((id) => id),
        signature,
      }),
    submitting: loading,
    confirmTitle: order && (
      <FormattedMessage
        id="discharge.order.no.x"
        defaultMessage="Discharge Order #{orderNo}"
        values={{ orderNo: order.get("orderNo") }}
      />
    ),
    confirmDescription: (
      <FormattedMessage
        id="total.discharged.x.items"
        defaultMessage="Total discharged {count} items"
        values={{ count: selects?.length }}
      />
    ),
    confirmSubmitText: <FormattedMessage id="discharge" defaultMessage="Discharge" />,
    confirmVisible,
    onConfirmClose: () => setConfirmVisible(false),
    onDischarge: () => {
      if (!selects?.length) {
        return toast.info(
          intl.formatMessage({
            id: "please.select.scan.discharge.items",
            defaultMessage: "Please select/scan discharge items",
          })
        );
      }

      setConfirmVisible(true);
    },
    receiptLines: response?.lines,
    onCloseReceipt: onClear,
    selects,
    setSelects,
    signature,
    setSignature,
    pending: pending.length > 0 && {
      items: pending,
      title: (
        <FormattedMessage id="pending.x" defaultMessage="Pending ({count})" values={{ count: pendingIndexes.length }} />
      ),
      dischargeDisabled:
        (totalBalance > 0 &&
          paidOnlyDischarge &&
          !corporate &&
          (!Array.isArray(selects) ||
            selects.length === 0 ||
            selects.some(
              (value) => !items?.find((item) => Array.isArray(item.returnLabels) && item.returnLabels.includes(value))
            ))) ||
        Boolean(
          businessCode && businessCode !== order?.get("business.id") && businessCode !== order?.get("collectionPlace")
        ),
      dischargeBtn: (
        <FormattedMessage
          id="discharge.x.items"
          defaultMessage="Discharge ({selected}/{total})"
          values={{
            selected: selects?.length || 0,
            total: pendingIndexes.length,
          }}
        />
      ),
      ...((!enforceScanToDischarge || corporate) && {
        selectAll: {
          title: <FormattedMessage id="select.all" defaultMessage="Select all" />,
          checked:
            Array.isArray(selects) &&
            selects.length > 0 &&
            Array.isArray(pendingIndexes) &&
            selects?.length === pendingIndexes?.length,
          indeterminate:
            Array.isArray(selects) &&
            selects.length > 0 &&
            Array.isArray(pendingIndexes) &&
            selects?.length < pendingIndexes?.length,
          onChange: (checked) => setSelects(checked ? pendingIndexes : []),
        },
      }),
      indexes: pendingIndexes,
    },
    complete: complete.length > 0 && {
      items: complete,
      indexes: completeIndexes,
      title: (
        <FormattedMessage
          id="discharged.x"
          defaultMessage="Discharged ({count})"
          values={{ count: completeIndexes.length }}
        />
      ),
    },
  };
}

export default useOrderDischargePanel;
