"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { doc, getFirestore, onSnapshot } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (businessCode) => {
  const realmId = useRealmId();
  const [cashDrawer, setCashDrawer] = useState();

  useEffect(() => {
    setCashDrawer(null);
    if (realmId && businessCode) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}/cashdrawer/${businessCode}`), setCashDrawer);
    }
  }, [realmId, businessCode]);

  return cashDrawer;
};
