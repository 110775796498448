"use client";

import { useCallback } from "react";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "@firebase/functions";

export default function useCallableFunction () {
  return useCallback((params) => {
    const functions = getFunctions();

    if (process.env.NODE_ENV === "development") {
      connectFunctionsEmulator(functions, "192.168.1.10", 5001);
    }

    return httpsCallable(functions, "operationCreate")(params);
  }, []);
}