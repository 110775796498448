import { useImageHost } from "@easybiz/context/src/ConfigurationContext";
import {
  PAYMENT_ID_ALIPAY,
  PAYMENT_ID_GRABPAY,
  PAYMENT_ID_PAYNOW_STRIPE,
  PAYMENT_ID_WECHATPAY,
  STRIPE_PAYMENT_METHOD_TYPE_GRABPAY,
} from "@easybiz/utils";
import { useMemo } from "react";
import { useIntl } from "react-intl";

function usePaymentNextActionQR(next_action, payment_method_types) {
  const intl = useIntl();
  const imageHost = useImageHost();

  return useMemo(() => {
    const { redirect_to_url, paynow_display_qr_code, wechat_pay_display_qr_code, alipay_handle_redirect } =
      next_action || {};

    if (paynow_display_qr_code) {
      return {
        content: paynow_display_qr_code.data,
        imageUrl: paynow_display_qr_code.image_url_png,
        logo: `${imageHost}/${PAYMENT_ID_PAYNOW_STRIPE}`,
        title: intl.formatMessage({
          id: "use.bank.app.scan.paynow.qr.below.to.complete.the.payment",
          defaultMessage: "Use bank App scan PAYNOW QR below to complete the payment.",
        }),
      };
    } else if (wechat_pay_display_qr_code) {
      return {
        content: wechat_pay_display_qr_code.data,
        imageUrl: wechat_pay_display_qr_code.image_url_png,
        logo: `${imageHost}/${PAYMENT_ID_WECHATPAY}`,
        title: intl.formatMessage({
          id: "use.wechat.app.scan.qr.below.to.complete.the.payment",
          defaultMessage: "Use Wechat App scan QR below to complete the payment.",
        }),
      };
    } else if (alipay_handle_redirect) {
      return {
        content: alipay_handle_redirect.url,
        logo: `${imageHost}/${PAYMENT_ID_ALIPAY}`,
        title: intl.formatMessage({
          id: "use.alipay.app.scan.qr.below.to.complete.the.payment",
          defaultMessage: "Use Alipay App scan QR below to complete the payment.",
        }),
      };
    } else if (redirect_to_url) {
      let paymentId;

      switch (Array.isArray(payment_method_types) ? payment_method_types[0] : null) {
        case STRIPE_PAYMENT_METHOD_TYPE_GRABPAY:
          paymentId = PAYMENT_ID_GRABPAY;
          break;
      }

      return {
        ...(paymentId && { logo: `${imageHost}/${paymentId}` }),
        content: redirect_to_url.url,
        title: intl.formatMessage({
          id: "ask.customer.scan.qr.code.below.to.complete.the.payment",
          defaultMessage: "Ask customer scan QR code below to complete the payment",
        }),
      };
    }
  }, [next_action, payment_method_types]);
}

export default usePaymentNextActionQR;
