
import { usePOSDevicePairingToken } from "@easybiz/api-pos";
import { doc, getFirestore, onSnapshot } from "@firebase/firestore";
import { QRCode, Result } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

export default function ({ pairingMode }) {
  const { onSubmit, response, loading } = usePOSDevicePairingToken();
  const [tokenDoc, setTokenDoc] = useState(null);

  useEffect(() => {
    if (onSubmit && pairingMode) {
      onSubmit({ pairingMode });
    }
  }, [Boolean(onSubmit), pairingMode]);

  useEffect(() => {
    if (response?.tokenPath) {
      const [realmId, tokenId] = `${response?.tokenPath}`.split("-");
      return onSnapshot(doc(getFirestore(), `realms/${realmId}/mobile_signins/${tokenId}`), setTokenDoc);
    } else {
      setTokenDoc(null);
    }
  }, [response?.tokenPath]);

  return tokenDoc?.exists() === false ? (
    <Result status="success" title={<FormattedMessage defaultMessage={"Device paired"} />} />
  ) : (
    <QRCode
      size={256}
      errorLevel="L"
      value={response?.tokenPath || " "}
      status={loading || (response?.tokenPath && !tokenDoc) ? "loading" : tokenDoc?.exists() ? "active" : "expired"}
      onRefresh={() => onSubmit({ pairingMode })}
    />
  );
}
