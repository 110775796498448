import {
  CheckOutlined,
  CloseOutlined,
  CrownOutlined,
  DeleteOutlined,
  GiftOutlined,
  NumberOutlined,
} from "@ant-design/icons";
import { AmountDisplay, NameDisplay } from "@easybiz/component";
import { usePrimaryColor } from "@easybiz/context/src/ThemingContext";
import { PROMO_TYPE_FOC, PROMO_TYPE_PERCENT } from "@easybiz/utils";
import { Avatar, Button, List, Popover, Switch, Tag, Typography } from "antd";
import React, { useState } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import PricingOverviewItemBase from "../PricingOverviewItemBase";
import Title from "../Title";

export default ({ promotion, onToggle, disabled: buttonDisabled }) => {
  // TODO: REMVOE code
  const {
    title,
    code,
    type,
    value,
    lineTotal,
    shortAmount,
    minimumOrder,
    disabled,
    duplicate,
    month,
    applied,
    id,
    member,
    campaign,
    promocode,
    expired,
    limitServiceTypes,
  } = promotion;
  const limited = Array.isArray(limitServiceTypes) && limitServiceTypes.length > 0;
  const [open, setVisible] = useState(false);
  const primaryColor = usePrimaryColor();

  return (
    <PricingOverviewItemBase
      title={
        <span>
          {title || code}
          {type === PROMO_TYPE_PERCENT ? (
            <span>
              (
              <FormattedMessage defaultMessage={"{percentage}% off"} values={{ percentage: value }} />)
            </span>
          ) : null}
        </span>
      }
      amount={lineTotal}
      isDiscount
      disabled={!applied}
      type="success"
      extra={
        !expired &&
        onToggle &&
        (promocode ? (
          <Button
            type="text"
            icon={<DeleteOutlined />}
            danger
            disabled={buttonDisabled}
            onClick={() => onToggle(id, false)}
          >
            <FormattedMessage tagName={"span"} defaultMessage="Remove" />
          </Button>
        ) : (
          <Switch
            checked={!disabled}
            disabled={buttonDisabled}
            onChange={(checked) => onToggle(id, checked)}
            checkedChildren={<FormattedMessage defaultMessage={"Enabled"} />}
            unCheckedChildren={<FormattedMessage defaultMessage={"Disabled"} />}
          />
        ))
      }
    >
      <div>
        {duplicate && (
          <Typography.Text type="secondary" strong>
            <FormattedMessage defaultMessage={"Percentage promotion can only apply one per order"} />
          </Typography.Text>
        )}
        <span>
          <Popover
            placement="left"
            open={limited && open}
            onOpenChange={setVisible}
            trigger="click"
            title={
              <Title>
                <FormattedMessage defaultMessage={"Discount apply to services below only"} />
              </Title>
            }
            content={
              <List
                dataSource={limitServiceTypes}
                renderItem={(id) => {
                  return (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Avatar size={"small"} style={{ backgroundColor: primaryColor }}>
                            <CheckOutlined />
                          </Avatar>
                        }
                        title={<NameDisplay nameKey={id} />}
                      />
                    </List.Item>
                  );
                }}
              />
            }
          >
            {campaign && (
              <Tag className={limited ? "selectable" : null} color={applied ? "success" : null} icon={<GiftOutlined />}>
                <FormattedMessage defaultMessage={"Promotion"} />
                {limited ? (
                  <b>
                    {" "}
                    (<FormattedMessage defaultMessage={"Limited"} />)
                  </b>
                ) : null}
              </Tag>
            )}
            {member && (
              <Tag
                className={limited ? "selectable" : null}
                color={applied ? "success" : null}
                icon={<CrownOutlined />}
              >
                <FormattedMessage defaultMessage={"Member discount"} />
                {limited ? (
                  <b>
                    {" "}
                    (<FormattedMessage defaultMessage={"Limited"} />)
                  </b>
                ) : null}
              </Tag>
            )}
            {promocode && (
              <Tag
                className={limited ? "selectable" : null}
                color={applied ? "success" : null}
                icon={<NumberOutlined />}
              >
                <FormattedMessage defaultMessage={"Promo code"} />
                {limited ? (
                  <b>
                    {" "}
                    (<FormattedMessage defaultMessage={"Limited"} />)
                  </b>
                ) : null}
              </Tag>
            )}
          </Popover>
          {typeof minimumOrder === "number" && (
            <Tag
              icon={shortAmount ? <CloseOutlined /> : <CheckOutlined />}
              color={applied ? (shortAmount ? "warning" : "success") : null}
            >
              <FormattedMessage
                defaultMessage={"Min. {amount}"}
                values={{
                  amount: <AmountDisplay value={minimumOrder} />,
                }}
              />
            </Tag>
          )}
          {typeof shortAmount === "number" && !disabled && (
            <Typography.Text type="warning" strong>
              <FormattedMessage
                defaultMessage={"Add {shortAmount} more to enjoy"}
                values={{
                  shortAmount: <AmountDisplay value={shortAmount} />,
                }}
              />
            </Typography.Text>
          )}
          {type === PROMO_TYPE_FOC && (
            <Typography.Text type={value === 0 ? "warning" : "success"}>
              <FormattedMessage
                defaultMessage={"{month} balance: {amount}"}
                values={{
                  month: <FormattedDate month="long" value={`${month}-01`} />,
                  amount: <AmountDisplay value={value} />,
                }}
              />
            </Typography.Text>
          )}
        </span>
      </div>
    </PricingOverviewItemBase>
  );
};
