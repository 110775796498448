"use client";

import { PLATFORM_WEB, PRINTER_IO_BLUETOOTH, PRINTER_TYPE_LABEL, PRINTER_TYPE_RECEIPT } from "@easybiz/utils";
import { createContext, useContext, useMemo, useState } from "react";
import { usePlatform } from "./ConfigurationContext";
import { useRegisteredDevice } from "./DeviceRegistrationContext";

const PrintingContext = createContext();

export function PrintingProvider({ ESCEncoder, usbIO, bluetoothIO, connectPrinter, children }) {
  const [bleReceiptPrinter, setBleReceiptPrinter] = useState();
  const [bleLabelPrinter, setBleLabelPrinter] = useState();

  const context = useMemo(() => {
    return {
      ESCEncoder,
      usbIO,
      bluetoothIO,
      connectPrinter,
      bleReceiptPrinter,
      bleLabelPrinter,
      setBleReceiptPrinter,
      setBleLabelPrinter,
    };
  }, [ESCEncoder, usbIO, bluetoothIO, connectPrinter, bleReceiptPrinter, bleLabelPrinter]);

  return <PrintingContext.Provider value={context}>{children}</PrintingContext.Provider>;
}

export const useESCEncoder = () => useContext(PrintingContext).ESCEncoder;
export const useUsbPrint = () => useContext(PrintingContext).usbIO;
export const useBluetoothPrint = () => useContext(PrintingContext).bluetoothIO;
export const usePrinterConnect = () => useContext(PrintingContext).connectPrinter;

export const useBleReceiptPrinter = () => useContext(PrintingContext).bleReceiptPrinter;
export const useSetBleReceiptPrinter = () => useContext(PrintingContext).setBleReceiptPrinter;
export const useBleLabelPrinter = () => useContext(PrintingContext).bleLabelPrinter;
export const useSetBleLabelPrinter = () => useContext(PrintingContext).setBleLabelPrinter;

export const useConnectedPrinter = (printerType) => {
  const platform = usePlatform();
  const bleReceiptPrinter = useBleReceiptPrinter();
  const bleLabelPrinter = useBleLabelPrinter();
  const deviceDoc = useRegisteredDevice();
  const printer = deviceDoc?.get(`${printerType}Printer`);

  if (platform === PLATFORM_WEB && printer?.io === PRINTER_IO_BLUETOOTH) {
    if (printerType === PRINTER_TYPE_RECEIPT && !bleReceiptPrinter) {
      return null;
    } else if (printerType === PRINTER_TYPE_LABEL && !bleLabelPrinter) {
      return null;
    }
  }

  return printer;
};
