export default (value, currency, accounting, currencyDisplay) => {
  if (typeof value !== "number") return "";

  return new Intl.NumberFormat(
    "en-US-POSIX",
    currency
      ? {
          style: "currency",
          currency,
          currencyDisplay: ["code", "symbol", "name"].includes(currencyDisplay) ? currencyDisplay : "narrowSymbol",
          ...(accounting && { currencySign: "accounting" }),
        }
      : { minimumFractionDigits: Number.isInteger(accounting) ? accounting : 2 }
  )
    .format(value)
    .replace(/\u00A0/g, " ");
};
