"use client";

import { calculateUnitIndex } from "@easybiz/utils";
import { useIntl } from "react-intl";
import ProcedureDisplay from "../ProcedureDisplay";

function useLabelStatusTag(order, sortIndex, checkProcedure) {
  const { procedures, itemsStatus, items } = order?.data() || {};
  const currentIndex = (itemsStatus && itemsStatus[sortIndex]) || 0;
  const { unitIndex, unitTotalPcs, unitPcsIndex, salesReturn, redo } = calculateUnitIndex(items, sortIndex);
  const checkIndex = Array.isArray(procedures) && checkProcedure ? procedures.indexOf(checkProcedure) : -1;
  const completed = checkProcedure ? currentIndex >= checkIndex : false;
  const intl = useIntl();

  return {
    backgroundColor: salesReturn || redo ? "#F9DEDC" : completed ? "#4cae4f" : `#eeeeee`,
    title: (
      <>
        {`#${unitIndex}${unitTotalPcs ? ` (${unitPcsIndex}/${unitTotalPcs})` : ""} `}
        <ProcedureDisplay procedure={procedures && procedures[currentIndex]} />
        {salesReturn
          ? ` [${intl.formatMessage({
              id: "sales.return",
              defaultMessage: "Sales return",
            })}]`
          : ""}
        {redo
          ? ` [${intl.formatMessage({
              id: "redo",
              defaultMessage: "Redo",
            })}]`
          : ""}
      </>
    ),
  };
}

export default useLabelStatusTag;
