import { DeleteOutlined } from "@ant-design/icons";
import { LineItemTitle, useLineItemDescriptions } from "@easybiz/component";
import { useImageHost } from "@easybiz/context/src/ConfigurationContext";
import { Avatar, Button } from "antd";
import OrderPhotoGallery from "../OrderPhotoGallery";
import PricingOverviewItemBase from "../PricingOverviewItemBase";

export default function ({ disabled, onSelect, item, selected, editOrder, actions, extra, type, onRemoveItem }) {
  const imageHost = useImageHost();
  const { photoId, lineTotal, qty, unit, unitPrice, labelIndexes } = item;
  const descriptions = useLineItemDescriptions(item);

  return (
    <PricingOverviewItemBase
      avatar={
        <div className="flex">
          <Avatar shape="square" src={photoId ? `${imageHost}/${photoId}` : "/images/defualt-cloth.jpg"} />
          {onRemoveItem && (
            <Button
              icon={<DeleteOutlined />}
              type="text"
              danger
              disabled={disabled}
              onClick={(e) => {
                e.stopPropagation();
                onRemoveItem(item);
              }}
            />
          )}
        </div>
      }
      amount={lineTotal}
      onClick={onSelect && !disabled ? () => onSelect(item) : null}
      unitPrice={unitPrice}
      qty={qty}
      unit={unit}
      selected={selected}
      actions={actions}
      extra={extra}
      type={type}
      title={<LineItemTitle item={item} />}
      descriptions={descriptions}
    >
      {editOrder && Array.isArray(labelIndexes) && labelIndexes.length === 1 && (
        <OrderPhotoGallery order={editOrder} itemIndex={labelIndexes[0]} limit={4} size={50} />
      )}
    </PricingOverviewItemBase>
  );
}
