import { CalendarOutlined, CreditCardOutlined, LockOutlined } from "@ant-design/icons";
import { DateDisplay } from "@easybiz/component";
import { useIsPOSDayClosed, usePOSSettings } from "@easybiz/context/src/POSContext";
import {
  useLicenseDuePeriod,
  useLicenseExpired,
  useLicenseTrailingEnds,
} from "@easybiz/context/src/SubscriptionStateContext";
import { getAuth, signOut } from "@firebase/auth";
import { Alert } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Dialog from "../Dialog";

export default function () {
  const invalid = useLicenseExpired();
  const duePeriod = useLicenseDuePeriod();
  const trialingEnds = useLicenseTrailingEnds();
  const intl = useIntl();
  const isDayClosed = useIsPOSDayClosed();
  const { closedDate } = usePOSSettings();

  if (invalid) {
    return (
      <Dialog
        open
        title={<FormattedMessage tagName="span" defaultMessage="Your license has expired!" />}
        okText={intl.formatMessage({ defaultMessage: "Sign out" })}
        onOk={() => signOut(getAuth())}
        disabled
      >
        <Alert
          type="info"
          message={
            <FormattedMessage
              defaultMessage="Please check your subscription from <b>Admin console</b>"
              values={{
                b: (t) => <b>{t}</b>,
              }}
            />
          }
          description={
            <FormattedMessage
              tagName="span"
              defaultMessage="You can still access your shop data using your admin account from the admin console."
            />
          }
        />
      </Dialog>
    );
  } else if (duePeriod) {
    return (
      <div className="padding-half">
        <Alert
          type="error"
          showIcon
          icon={<CreditCardOutlined />}
          message={
            <FormattedMessage
              defaultMessage="Subscription due, your license will be <b>suspend {date}</b>"
              values={{ date: duePeriod, b: (t) => <b>{t}</b> }}
            />
          }
        />
      </div>
    );
  } else if (trialingEnds) {
    return (
      <div className="padding-half">
        <Alert
          type="info"
          showIcon
          icon={<CalendarOutlined />}
          message={
            <FormattedMessage
              defaultMessage={`<b>[Trialing]</b> Your trial will end {fromNow}`}
              values={{
                fromNow: <DateDisplay timestamp={trialingEnds} />,
                b: (t) => <b>{t}</b>,
              }}
            />
          }
        />
      </div>
    );
  } else if (isDayClosed) {
    return (
      <div className="padding-half">
        <Alert
          type="info"
          showIcon
          icon={<LockOutlined />}
          message={
            <FormattedMessage
              defaultMessage={
                "Close of day submitted for {date}, you can not do sales related operations until tomorrow"
              }
              values={{
                date: (
                  <b>
                    <DateDisplay date={closedDate} />
                  </b>
                ),
              }}
            />
          }
        />
      </div>
    );
  } else {
    return null;
  }
}
