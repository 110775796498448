"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { collection, getFirestore, onSnapshot, orderBy, query } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default () => {
  const realmId = useRealmId();
  const [options, setOptions] = useState(null);

  useEffect(() => {
    setOptions(null);
    if (realmId) {
      return onSnapshot(
        query(collection(getFirestore(), `realms/${realmId}/topup_options`), orderBy("amount")),
        (result) => setOptions(result.docs)
      );
    }
  }, [realmId]);

  return options;
};
