"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { STATUSES_KEY_COUNTER } from "@easybiz/utils";
import { doc, getFirestore, onSnapshot } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (key) => {
  const realmId = useRealmId();
  const [statusDoc, setStatusDoc] = useState(null);

  useEffect(() => {
    setStatusDoc(null);
    if (realmId && key) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/statuses/${STATUSES_KEY_COUNTER}/keys/${key}`),
        setStatusDoc
      );
    }
  }, [realmId, key]);

  return statusDoc;
};
