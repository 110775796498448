import { useCurrency } from "@easybiz/context/src/ConfigurationContext";
import { FormattedNumber } from "react-intl";

function AmountDisplay({ value, pure, accounting, currency: initCurrency, minimumFractionDigits }) {
  const currency = useCurrency();

  return (
    <FormattedNumber
      value={typeof value === "number" ? value : value && !isNaN(value) ? parseFloat(value) : 0}
      minimumFractionDigits={minimumFractionDigits ?? 2}
      maximumFractionDigits={2}
      {...((initCurrency || currency) &&
        !pure && {
          currency: initCurrency || currency,
          style: "currency",
          currencyDisplay: "narrowSymbol",
          ...(accounting && {
            currencySign: "accounting",
          }),
        })}
    />
  );
}

export default AmountDisplay;
