import { EnvironmentOutlined } from "@ant-design/icons";
import { POSWrongLocationDisplay } from "@easybiz/component";
import { useHaveNetwork } from "@easybiz/context/src/ConfigurationContext";
import { useDeviceLocation, useDeviceLocationSetter } from "@easybiz/context/src/DeviceRegistrationContext";
import { usePOSSettings } from "@easybiz/context/src/POSContext";
import { Alert } from "antd";
import dayjs from "dayjs";
import haversine from "haversine-distance";
import { useEffect } from "react";

export default function LocationMonitor() {
  const { stationLocation } = usePOSSettings();
  const haveNetwork = useHaveNetwork();
  const location = useDeviceLocation();
  const setLocation = useDeviceLocationSetter();

  useEffect(() => {
    if (haveNetwork && "geolocation" in navigator) {
      const reportLocation = (source, target) => {
        setLocation((currentLocation) => {
          const distance = Math.round((haversine(target, source.coords) / 1000) * 10) / 10;
          const failed = distance >= 1;

          if (
            !currentLocation ||
            (currentLocation.failed && !failed) ||
            (!currentLocation.failed && failed) ||
            dayjs().diff(dayjs.unix(currentLocation.timestamp), "seconds") >= 60
          ) {
            return {
              latitude: source.coords.latitude,
              longitude: source.coords.longitude,
              accuracy: source.coords.accuracy,
              timestamp: dayjs().unix(),
              distance,
              failed,
            };
          }

          return currentLocation;
        });
      };

      const callback = () => {
        navigator.geolocation.getCurrentPosition((location) => reportLocation(location, stationLocation));
      };

      const handler = setInterval(callback, 5 * 60 * 1000);

      callback();

      const watchId = navigator.geolocation.watchPosition((location) => reportLocation(location, stationLocation));

      return () => {
        navigator.geolocation.clearWatch(watchId);
        clearInterval(handler);
      };
    }
  }, [haveNetwork, setLocation, stationLocation?.lat, stationLocation?.lng]);

  return location?.failed ? (
    <div className="padding-half">
      <Alert
        type="error"
        icon={<EnvironmentOutlined />}
        showIcon
        message={<POSWrongLocationDisplay location={location} />}
      />
    </div>
  ) : null;
}
