"use client";

import {
  BUSINESS_SECTOR_ALTERATION,
  BUSINESS_SECTOR_BAG_SERVICE,
  BUSINESS_SECTOR_LAUNDRY,
  isPaymentTypeSupported,
} from "@easybiz/utils";
import { createContext, useContext, useMemo } from "react";

const RealmContext = createContext();

export function RealmProvider({ children, businessCode, realmDoc }) {
  const context = useMemo(() => {
    const { services, publicServices, currency } = realmDoc?.data() || {};

    const businesses = (services || [])
      .map(({ codes, agent, agents, ...service }) => {
        if (
          !businessCode ||
          (Array.isArray(codes) &&
            codes.indexOf(businessCode) >= 0 &&
            [BUSINESS_SECTOR_LAUNDRY, BUSINESS_SECTOR_BAG_SERVICE, BUSINESS_SECTOR_ALTERATION].indexOf(
              service.sector
            ) >= 0)
        ) {
          const supplierId = (agents && agents[businessCode]) || agent;

          return {
            ...service,
            ...(supplierId && { supplierId }),
            ...(Array.isArray(codes) && { codes }),
          };
        }
      })
      .filter((service) => service);
    let suppliers = businesses.map((service) => service.supplierId).filter((id) => id);
    suppliers = suppliers.filter((id, index) => suppliers.indexOf(id) === index);

    // Online payment methods
    const capabilities = realmDoc?.get("capabilities") || {};
    const supportOnlinePaymentMethods = Object.keys(capabilities)
      .map((key) => {
        if (key.endsWith("_payments")) {
          const id = key.split("_payments")[0];
          if (isPaymentTypeSupported(id, currency)) {
            return id;
          }
        }
      })
      .filter(Boolean)
      .sort()
      .map((id) => ({ id, status: capabilities[`${id}_payments`] }));

    return {
      ready: Boolean(realmDoc),
      businesses,
      ...(suppliers.length > 0 && { suppliers }),
      countryName: realmDoc?.get("country.name"),
      timeZone: realmDoc?.get("timeZone"),
      stripeAccount: realmDoc?.get("stripeAccount"),
      companys: realmDoc?.get("companys"),
      sectors: realmDoc?.get("sectors"),
      posStripeMethods: realmDoc?.get("posStripeMethods"),
      centralFactory: realmDoc?.get("factory"),
      collectionPoints: realmDoc?.get("collectionPoints"),
      posSettings: realmDoc?.get("posSettings") || {},
      memberSettings: realmDoc?.get("memberSettings") || {},
      logisticSettings: realmDoc?.get("logisticSettings") || {},
      productionSettings: realmDoc?.get("productionSettings") || {},
      labelSettings: realmDoc?.get("labelSettings") || {},
      recyclables: realmDoc?.get("recyclables"),
      whiteList: realmDoc?.get("whiteList"),
      salesByClose: realmDoc?.get("salesByClose"),
      manual: realmDoc?.get("manual"),
      departments: realmDoc?.get("departments"),
      publicServices: Array.isArray(publicServices) && publicServices.length > 0 ? publicServices : null,
      supportOnlinePaymentMethods,
    };
  }, [realmDoc, businessCode]);

  return <RealmContext.Provider value={context}>{children}</RealmContext.Provider>;
}

export const useIsRealmReady = () => useContext(RealmContext).ready;
export const useDepartments = () => useContext(RealmContext).departments;
export const useDefaultDepartment = () => {
  const departments = useContext(RealmContext).departments;
  return (Array.isArray(departments) && departments[0]) || "OTHERS";
};
export const useIsWhitelistEnforced = (clientType) => Boolean(useContext(RealmContext).whiteList?.[clientType]);
export const useIsCentralFactoryEnabled = () => useContext(RealmContext).centralFactory;
export const useCollectionPoints = () => useContext(RealmContext).collectionPoints;
export const usePOSSettings = () => useContext(RealmContext).posSettings;
export const useLogisticSettings = () => useContext(RealmContext).logisticSettings;
export const useProductionSettings = () => useContext(RealmContext).productionSettings;
export const useLabelSettings = () => useContext(RealmContext).labelSettings;
export const useMemberSettings = () => useContext(RealmContext).memberSettings;
export const usePublicBusinesses = () => useContext(RealmContext).publicServices;
export const useRecyclables = () => useContext(RealmContext).recyclables;
export const useBusinessSectors = () => useContext(RealmContext).sectors;
export const useBusinesses = (businessCode) => {
  const { businesses } = useContext(RealmContext);
  if (businessCode) {
    return businesses?.filter(({ codes }) => Array.isArray(codes) && codes.indexOf(businessCode) >= 0);
  } else {
    return businesses;
  }
};
export const useSuppliers = () => useContext(RealmContext).suppliers;
export const useIsBusinessSlotReady = (serviceId) => {
  const { businesses } = useContext(RealmContext);
  return Boolean(businesses?.find((service) => service.slot && service.id === serviceId));
};
export const useHasBusinessSector = (sector) => {
  const { sectors } = useContext(RealmContext);
  return Array.isArray(sectors) ? sectors.indexOf(sector) >= 0 : false;
};
export const useHasPudBusiness = () => {
  const { sectors } = useContext(RealmContext);
  return Boolean(
    sectors?.find(
      (sector) =>
        [BUSINESS_SECTOR_LAUNDRY, BUSINESS_SECTOR_BAG_SERVICE, BUSINESS_SECTOR_ALTERATION].indexOf(sector) >= 0
    )
  );
};
export const useBusinessSector = (serviceId) => {
  const { businesses } = useContext(RealmContext);
  return businesses?.find((service) => service.id === serviceId)?.sector;
};

export const useAccountingSalesByClose = () => useContext(RealmContext).salesByClose;
export const useCountryName = () => useContext(RealmContext).countryName;
export const useTimeZone = () => useContext(RealmContext).timeZone;
export const useStripeAccount = () => useContext(RealmContext).stripeAccount;
export const useCompanies = () => useContext(RealmContext).companys;
export const useSupportOnlinePaymentMethods = () => useContext(RealmContext).supportOnlinePaymentMethods;
export const usePOSEnabledOnlinePaymentMethods = () => useContext(RealmContext).posStripeMethods;
