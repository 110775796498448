import { Card, Divider, Space } from "antd";
import React from "react";
import ProductBadgeImage from "../ProductBadgeImage";
import { useSecondaryContainerColor } from "@easybiz/context/src/ThemingContext";

export default ({ subTypes, select, onSelect, size = 60 }) => {
  const selectColor = useSecondaryContainerColor();

  return (
    <Space direction="vertical" className="full-width">
      <Space wrap direction="horizontal">
        {(subTypes || []).map(({ id, title, photoId, qty }) => {
          const selected = id === select;

          return (
            <Card
              key={`${id}`}
              className="selectable"
              {...(selected && { style: { backgroundColor: selectColor } })}
              onClick={() => onSelect(id)}
              bodyStyle={{ padding: 8 }}
            >
              <ProductBadgeImage width={size} photoId={photoId} count={qty} />
              <div
                style={{
                  paddingTop: 2,
                  lineHeight: "1.5em",
                  height: "3em",
                  width: size,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign: "center",
                }}
              >
                {title}
              </div>
            </Card>
          );
        })}
      </Space>
      <Divider style={{ margin: 0 }} />
      <span />
    </Space>
  );
};
