"use client";

import { initializeAppCheck, ReCaptchaV3Provider } from "@firebase/app-check";
import { initializeApp } from "@firebase/app";

export default (config) => {
  const app = initializeApp(config);

  if (process.env.NODE_ENV === "production" && typeof window !== "undefined") {
    initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(config.siteKey),
      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      isTokenAutoRefreshEnabled: true,
    });
  }

  return app;
};
