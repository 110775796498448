"use client";

import { getId, getInstallations, onIdChange } from "@firebase/installations";
import { useEffect, useState } from "react";

export default () => {
  const [deviceId, setDeviceId] = useState();

  useEffect(() => {
    getId(getInstallations()).then(setDeviceId);

    return onIdChange(getInstallations(), setDeviceId);
  }, []);

  return deviceId;
};
