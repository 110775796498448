"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { formatCard } from "@easybiz/utils";
import { collection, getFirestore, onSnapshot, orderBy, query } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (customerId, formatData) => {
  const realmId = useRealmId();
  const [methods, setMethods] = useState(null);

  useEffect(() => {
    setMethods(null);
    if (realmId && customerId) {
      return onSnapshot(
        query(
          collection(getFirestore(), `realms/${realmId}/stripe_customers/${customerId}/payment_methods`),
          orderBy("created")
        ),
        (result) => setMethods(formatData ? result.docs.map(formatCard) : result.docs)
      );
    }
  }, [realmId, customerId, formatData]);

  return methods;
};
