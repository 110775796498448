import { EditOutlined } from "@ant-design/icons";
import { ReceiptName } from "@easybiz/component";
import { CLIENT_POS, EVENT_TYPE_ORDER_BILL_RECEIPT } from "@easybiz/utils";
import { useOrderReceiptDoc } from "@easybiz/web-firebase";
import { Button, Space, Tabs, Typography } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import CopyOrderButton from "../CopyOrderButton";
import EditNoteDrawer from "../EditNoteDrawer";
import OrderRefreshReceiptButton from "../OrderRefreshReceiptButton";
import PrintReceiptButton from "../PrintReceiptButton";
import ReceiptEmailButton from "../ReceiptEmailButton";
import ReceiptView from "../ReceiptView";
import { useClientType } from "@easybiz/context/src/ConfigurationContext";
import { useToDisplayName } from "@easybiz/context/src/NamingContext";

export default ({ orderId, order, placement, permissions }) => {
  const { customer, salesRemark } = order?.data() || {};
  const receipt = useOrderReceiptDoc(orderId);
  const clientType = useClientType();
  const mutiLines = receipt?.get("mutiLines");
  const toDisplayName = useToDisplayName();
  const [noteVisible, setNoteVisible] = useState(false);

  return (
    <Space direction="vertical" className="full-width">
      {salesRemark && (
        <Typography.Text type="danger" strong>
          {` ** ${salesRemark}`}
        </Typography.Text>
      )}
      <div className="flex flex-row flex-space-between">
        <PrintReceiptButton
          disabled={!receipt}
          type="primary"
          mutiLines={mutiLines}
          lines={receipt?.get("lines")}
        />
        <Space>
          {clientType === CLIENT_POS ? <CopyOrderButton order={order} /> : <span />}
          <Button
            icon={<EditOutlined />}
            disabled={!permissions?.canNote}
            type="text"
            onClick={() => setNoteVisible(true)}
          >
            <FormattedMessage tagName={"span"} defaultMessage={"Note"} />
          </Button>
          <ReceiptEmailButton orderId={orderId} customer={customer} eventType={EVENT_TYPE_ORDER_BILL_RECEIPT} />
        </Space>
      </div>
      {Array.isArray(mutiLines) && mutiLines.length > 0 ? (
        <Tabs type="card" centered>
          {mutiLines.map(({ lines, name }, index) => (
            <Tabs.TabPane key={`${index}`} tab={<ReceiptName name={name} />}>
              <ReceiptView
                lines={lines}
                downloadTitle={order && `${toDisplayName(order.get("business.id"))}-${order.get("orderNo")}`}
                printing
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
      ) : (
        <ReceiptView loading={!receipt} lines={receipt?.get("lines") || (mutiLines && mutiLines[0]?.lines)} />
      )}
      <OrderRefreshReceiptButton orderId={orderId} customerId={order?.get("customer.id")} />
      <EditNoteDrawer open={noteVisible} order={order} onCancel={() => setNoteVisible(false)} placement={placement} />
    </Space>
  );
};
