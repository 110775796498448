import { Empty, List } from "antd";
import React from "react";
import LoadMoreButton from "../LoadMoreButton";
import OrderListItem from "../OrderListItem";
import { useShowPhoto } from "@easybiz/context/src/LocalSettingContext";

export default function ({
  header,
  loading,
  orders,
  search,
  onLoadMore,
  selects,
  selectId,
  onSelect,
  mutipleSelect,
  pagination,
  emptyText,
  fullOrderNo,
}) {
  const showPhoto = useShowPhoto();

  return (
    <List
      header={header}
      size="small"
      locale={emptyText ? { emptyText: <Empty description={emptyText} /> } : undefined}
      loading={Boolean(loading && !orders)}
      dataSource={orders || []}
      renderItem={(order) => (
        <OrderListItem
          order={order}
          search={search}
          showPhoto={showPhoto}
          selects={selects}
          selectId={selectId}
          onSelect={onSelect}
          mutipleSelect={mutipleSelect}
          fullOrderNo={fullOrderNo}
        />
      )}
      pagination={pagination}
      footer={onLoadMore && <LoadMoreButton dataSource={orders} loading={loading} onClick={onLoadMore} />}
    />
  );
}
