import { useCurrency } from "@easybiz/context/src/ConfigurationContext";
import { useCustomerTopUpOptions } from "@easybiz/web-firebase";
import { Avatar, Checkbox, List, Tag } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function ({ header, customerId, select, onSelect }) {
  const currency = useCurrency();
  const options = useCustomerTopUpOptions(customerId);

  return (
    <List
      header={header}
      dataSource={options || []}
      loading={!options}
      renderItem={(option, index) => {
        const { amount, giftAmount, oneTimeGiftAmount } = option;
        const gift = oneTimeGiftAmount || giftAmount;

        let title, description;

        if (oneTimeGiftAmount || giftAmount) {
          title = (
            <FormattedMessage
              tagName="span"
              defaultMessage="Top up <b>{currency}{topUpAmount}</b>, get <green>{currency}{giftAmount}</green> free"
              values={{
                topUpAmount: amount,
                giftAmount: gift,
                currency,
                green: (t) => <b style={{ color: "green" }}>{t}</b>,
                b: (t) => <b>{t}</b>,
              }}
            />
          );
          if (oneTimeGiftAmount && giftAmount) {
            description = (
              <Tag>
                <FormattedMessage
                  tagName="span"
                  defaultMessage="Next top up get <b>{currency}{giftAmount}</b> free"
                  values={{ currency, giftAmount, b: (t) => <b>{t}</b> }}
                />
              </Tag>
            );
          }
        } else {
          title = (
            <FormattedMessage
              tagName="span"
              defaultMessage="Top up <b>{amount}</b>"
              values={{
                amount: `${currency}${amount}`,
                b: (t) => <b style={{ color: "green" }}>{t}</b>,
              }}
            />
          );
        }

        return (
          <List.Item
            actions={onSelect ? [<Checkbox checked={select && select.id === option.id ? true : false} />] : null}
            className={onSelect ? "selectable" : null}
            {...(onSelect && { onClick: () => onSelect(option) })}
          >
            <List.Item.Meta
              title={<span style={{ fontSize: 16 }}>{title}</span>}
              description={description}
              avatar={<Avatar>#{index + 1}</Avatar>}
            />
          </List.Item>
        );
      }}
    />
  );
}
