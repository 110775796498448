"use client";

import { createContext, useContext, useMemo } from "react";
import { useClientDate } from "./LiveStateContext";

const POSContext = createContext();

export function POSProvider({ businessSetting, children }) {
  const context = useMemo(() => {
    return {
      businessSetting: businessSetting || {},
    };
  }, [businessSetting]);

  return <POSContext.Provider value={context}>{children}</POSContext.Provider>;
}

export const usePOSSettings = () => useContext(POSContext).businessSetting;
export const useIsPOSDayClosed = () => {
  const date = useClientDate();
  const { closedDate } = usePOSSettings(date);
  return closedDate === date;
};
