"use client";

import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuthUser } from "./AuthenticationContext";
import { useBusinessCode } from "./ConfigurationContext";

const LocalStorageContext = createContext();

const KEY_SCANNER = "scanner";
const KEY_PRODUCTION_STORE = "productionStore";
const KEY_AUTO_PRINT_RECEIPT = "auto_receipt";
const KEY_AUTO_PRINT_INTERNAL_RECEIPT = "auto_internal_receipt";
const KEY_AUTO_PRINT_LAEBL = "auto_label";
const KEY_AUTO_PRINT_STICKER = "auto_sticker";
const KEY_JOB_MAP_MODE = "job_map_mode";
const KEY_DEFAULT_DEPARTMENT = "DEFAULT_SERVICE_MERK";
const KEY_COMPLETE_SHOW_LABEL = "complete_show_label";
const KEY_PHOTO_CAPTURE = "photo_capture";
const KEY_SHOW_PHOTO = "show_photo";
const KEY_SEARCH_TABS = "search_tabs";
const KEY_PROXY_UPLOAD = "proxy_upload";
const KEY_CAMERA_SHUTTER = "shutter";
const KEY_CAMERA_FLASH = "flash";
const KEY_YES = "YES";

export function LocalSettingProvider({ LocalStorage, children }) {
  const [localSettings, setLocalSettings] = useState();
  const authUser = useAuthUser();

  useEffect(() => {
    if (LocalStorage && authUser) {
      Promise.all([
        LocalStorage.getItem(KEY_SCANNER),
        LocalStorage.getItem(KEY_PRODUCTION_STORE),
        LocalStorage.getItem(KEY_AUTO_PRINT_RECEIPT),
        LocalStorage.getItem(KEY_AUTO_PRINT_INTERNAL_RECEIPT),
        LocalStorage.getItem(KEY_AUTO_PRINT_LAEBL),
        LocalStorage.getItem(KEY_AUTO_PRINT_STICKER),
        LocalStorage.getItem(KEY_JOB_MAP_MODE),
        LocalStorage.getItem(KEY_DEFAULT_DEPARTMENT),
        LocalStorage.getItem(KEY_COMPLETE_SHOW_LABEL),
        LocalStorage.getItem(KEY_PHOTO_CAPTURE),
        LocalStorage.getItem(KEY_SHOW_PHOTO),
        LocalStorage.getItem(KEY_SEARCH_TABS),
        LocalStorage.getItem(KEY_PROXY_UPLOAD),
        LocalStorage.getItem(KEY_CAMERA_SHUTTER),
        LocalStorage.getItem(KEY_CAMERA_FLASH),
      ]).then((reuslts) => {
        let [
          scannerOpen,
          productionStore,
          autoCusReceipt,
          autoInternalReceipt,
          autoLabel,
          autoSticker,
          jobMapMode,
          defaultDepartment,
          showLabel,
          photoCapture,
          showPhoto,
          searchTabs,
          proxyUpload,
          shutter,
          flash,
        ] = reuslts;

        if (searchTabs) {
          try {
            searchTabs = JSON.parse(searchTabs);
          } catch (e) {
            // Ignore
          }
        }

        setLocalSettings({
          scannerOpen: scannerOpen !== "disabled",
          productionStore,
          autoCusReceipt:
            autoCusReceipt && !isNaN(autoCusReceipt) ? parseInt(autoCusReceipt) : autoCusReceipt === KEY_YES ? 1 : 0,
          autoInternalReceipt:
            autoInternalReceipt && !isNaN(autoInternalReceipt)
              ? parseInt(autoInternalReceipt)
              : autoInternalReceipt === KEY_YES
              ? 1
              : 0,
          autoLabel: autoLabel === KEY_YES,
          autoSticker: autoSticker === KEY_YES,
          jobMapMode: jobMapMode === KEY_YES,
          defaultDepartment: defaultDepartment || null,
          showLabel: Boolean(showLabel) ?? false,
          photoCapture: Boolean(photoCapture),
          showPhoto: Boolean(showPhoto) ?? true,
          proxyUpload: Boolean(proxyUpload),
          shutter: (shutter || KEY_YES) === KEY_YES,
          flash,
          ...(Array.isArray(searchTabs) && { searchTabs }),
        });
      });
    }
  }, [Boolean(authUser)]);

  const context = useMemo(() => {
    function localSave(key, value) {
      if (LocalStorage) {
        if (value) {
          LocalStorage.setItem(key, value);
        } else {
          LocalStorage.removeItem(key);
        }
      }
    }

    return {
      ...localSettings,
      setScannerOpen: (open) => {
        setLocalSettings((localSettings) => ({ ...localSettings, scannerOpen: Boolean(open) }));
        localSave(KEY_SCANNER, open ? null : "disabled");
      },
      setProductionStore: (store) => {
        setLocalSettings((localSettings) => ({ ...localSettings, productionStore: store }));
        localSave(KEY_PRODUCTION_STORE, store);
      },
      setDefaultDepartment: (department) => {
        setLocalSettings((localSettings) => ({ ...localSettings, defaultDepartment: department }));
        localSave(KEY_DEFAULT_DEPARTMENT, department);
      },
      setAutoCusReceipt: (data) => {
        if (Number.isInteger(data)) {
          setLocalSettings((localSettings) => ({ ...localSettings, autoCusReceipt: data }));
          localSave(KEY_AUTO_PRINT_RECEIPT, data > 0 ? `${data}` : null);
        }
      },
      setAutoInternalReceipt: (data) => {
        if (Number.isInteger(data)) {
          setLocalSettings((localSettings) => ({ ...localSettings, autoInternalReceipt: data }));
          localSave(KEY_AUTO_PRINT_INTERNAL_RECEIPT, data > 0 ? `${data}` : null);
        }
      },
      setAutoLabel: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, autoLabel: Boolean(data) }));
        localSave(KEY_AUTO_PRINT_LAEBL, data ? KEY_YES : null);
      },
      setAutoSticker: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, autoSticker: Boolean(data) }));
        localSave(KEY_AUTO_PRINT_STICKER, data ? KEY_YES : null);
      },
      setJobMapMode: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, jobMapMode: Boolean(data) }));
        localSave(KEY_JOB_MAP_MODE, data ? KEY_YES : null);
      },
      setShowLabel: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, showLabel: Boolean(data) }));
        localSave(KEY_COMPLETE_SHOW_LABEL, data ? KEY_YES : null);
      },
      setPhotoCapture: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, photoCapture: Boolean(data) }));
        localSave(KEY_PHOTO_CAPTURE, data ? KEY_YES : null);
      },
      setShowPhoto: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, showPhoto: Boolean(data) }));
        localSave(KEY_SHOW_PHOTO, data ? KEY_YES : null);
      },
      setSearchTabs: (data) => {
        if (Array.isArray(data)) {
          setLocalSettings((localSettings) => ({ ...localSettings, searchTabs: data }));
          localSave(KEY_SEARCH_TABS, JSON.stringify(data));
        }
      },
      setProxyUpload: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, proxyUpload: Boolean(data) }));
        localSave(KEY_PROXY_UPLOAD, data ? KEY_YES : null);
      },
      setCameraShutter: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, shutter: Boolean(data) }));
        localSave(KEY_CAMERA_SHUTTER, data ? KEY_YES : "disabled");
      },
      setCameraFlash: (data) => {
        setLocalSettings((localSettings) => ({ ...localSettings, flash: data }));
        localSave(KEY_CAMERA_FLASH, data);
      },
    };
  }, [localSettings]);

  return <LocalStorageContext.Provider value={context}>{children}</LocalStorageContext.Provider>;
}

export const useScannerOpen = () => useContext(LocalStorageContext).scannerOpen;
export const useSetScannerOpen = () => useContext(LocalStorageContext).setScannerOpen;

export const useWorkingStore = () => {
  const { productionStore } = useContext(LocalStorageContext);
  const businessCode = useBusinessCode();

  return productionStore || businessCode;
};
export const useSetWorkingStore = () => useContext(LocalStorageContext).setProductionStore;

export const useAutoCusReceipt = () => useContext(LocalStorageContext).autoCusReceipt;
export const useSetAutoCusReceipt = () => useContext(LocalStorageContext).setAutoCusReceipt;

export const useAutoInternalReceipt = () => useContext(LocalStorageContext).autoInternalReceipt;
export const useSetAutoInternalReceipt = () => useContext(LocalStorageContext).setAutoInternalReceipt;

export const useAutoLabel = () => useContext(LocalStorageContext).autoLabel;
export const useSetAutoLabel = () => useContext(LocalStorageContext).setAutoLabel;

export const useAutoSticker = () => useContext(LocalStorageContext).autoSticker;
export const useSetAutoSticker = () => useContext(LocalStorageContext).useSetAutoSticker;

export const useJobMapMode = () => useContext(LocalStorageContext).jobMapMode;
export const useSetJobMapMode = () => useContext(LocalStorageContext).setJobMapMode;

export const useProcessingDepartment = () => useContext(LocalStorageContext).defaultDepartment;
export const useSetDefaultDepartment = () => useContext(LocalStorageContext).setDefaultDepartment;

export const useShowLabel = () => useContext(LocalStorageContext).showLabel;
export const useSetShowLabel = () => useContext(LocalStorageContext).setShowLabel;

export const usePhotoCapture = () => useContext(LocalStorageContext).photoCapture;
export const useSetPhotoCapture = () => useContext(LocalStorageContext).setPhotoCapture;

export const useShowPhoto = () => useContext(LocalStorageContext).showPhoto;
export const useSetShowPhoto = () => useContext(LocalStorageContext).setShowPhoto;

export const useSearchTabs = () => useContext(LocalStorageContext).searchTabs;
export const useSetSearchTabs = () => useContext(LocalStorageContext).setSearchTabs;

export const useCameraShutter = () => useContext(LocalStorageContext).shutter;
export const useSetCameraShutter = () => useContext(LocalStorageContext).setCameraShutter;

export const useCameraFlash = () => useContext(LocalStorageContext).flash;
export const useSetCameraFlash = () => useContext(LocalStorageContext).setCameraFlash;