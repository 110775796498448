import { CheckOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { useDefaultDialCode } from "@easybiz/context/src/ConfigurationContext";
import { Tag, Tooltip } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default function ({ verifiedContact, tels, contacts, dialCodes, whatsApp, callable }) {
  const dialCode = useDefaultDialCode();

  if (!tels) {
    // Backward support only
    tels = (contacts || []).map((contact, index) => {
      return {
        number: contact,
        dialCode: Array.isArray(dialCodes) ? dialCodes[index] || "" : dialCode,
      };
    });
  }

  return (
    <span>
      {(tels || []).map(({ number, dialCode, intl, uri }) => {
        const verified = `${dialCode}${number}` === verifiedContact;

        let content = intl;

        if (!content) {
          content = `+${dialCode} ${number}`;
        }

        if (whatsApp) {
          content = (
            <a href={`https://wa.me/${dialCode}${number}`} rel="noreferrer" target="_blank">
              <WhatsAppOutlined /> {content}
            </a>
          );
        } else if (uri && callable) {
          content = <a href={uri}>{content}</a>;
        }

        if (verified) {
          return (
            <Tooltip key={number} title={<FormattedMessage defaultMessage="Verified" />}>
              <Tag color="success" icon={<CheckOutlined />}>
                {content}
              </Tag>
            </Tooltip>
          );
        } else {
          return <span key={number}>{content} </span>;
        }
      })}
    </span>
  );
}
