import { useToast } from "@easybiz/context/src/ConfigurationContext";
import { useActionCallback } from "@easybiz/hook";
import { OPERATION_GROUP_ORDER, ORDER_ACTION_LINE_ITEM_EDIT } from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function useOrderLineItemEdit(callback) {
  const toast = useToast();
  const intl = useIntl();

  const onSubmit = useCallback(({ newPricing, orderId, confirmed }) => {
    return [
      OPERATION_GROUP_ORDER,
      ORDER_ACTION_LINE_ITEM_EDIT,
      {
        newPricing,
        orderId,
        ...(confirmed && { confirmed }),
      },
    ];
  }, []);

  return useActionCallback(onSubmit, callback, (request, response) =>
    toast.success(
      response.isConfirm
        ? intl.formatMessage({
            id: "order.confirmed",
            defaultMessage: "Order confirmed",
          })
        : intl.formatMessage({
            id: "order.line.item.updated",
            defaultMessage: "Order line item updated",
          })
    )
  );
}

export default useOrderLineItemEdit;
