"use client";

import { createContext, useContext, useMemo } from "react";

const AuthenticationContext = createContext();

export function AuthenticationProvider({ client, account, setAccount, avatarUrl, onSignOut, accountName, children }) {
  const context = useMemo(() => {
    return {
      account,
      setAccount,
      uid: account?.uid,
      token: account?.token,
      customerId: account?.customerId,
      requireSignIn: account === false,
      signInEmail: account?.email,
      emailVerified: account?.emailVerified,
      realmMaster: account?.realmMaster,
      platformMode: account?.platformMode,
      pendingSetup: account?.pendingSetup,
      registeredDeviceId: account?.installId,
      connectDeviceId: account?.connectDeviceId,
      checkInStaff: account?.checkInStaff,
      permissions: account?.permissions,
      onSignOut,
      avatarUrl: avatarUrl || account?.avatarUrl,
      operator: account
        ? {
            id: account.customerId || account.uid,
            name: account.checkInStaff ? account.checkInStaff.name : accountName || account.name || "",
          }
        : null,
    };
  }, [client, account, avatarUrl, onSignOut, accountName]);

  return <AuthenticationContext.Provider value={context}>{children}</AuthenticationContext.Provider>;
}

export const useAuthUser = () => useContext(AuthenticationContext).account;
export const useAuthUserToken = () => useContext(AuthenticationContext).token;
export const useUserPermissions = () => useContext(AuthenticationContext).permissions;
export const useSetAuthUser = () => useContext(AuthenticationContext).setAccount;
export const useCustomerId = () => useContext(AuthenticationContext).customerId;
export const useOperator = () => useContext(AuthenticationContext).operator;
export const useSignInAvatarUrl = () => useContext(AuthenticationContext).avatarUrl;
export const useSignInUID = () => useContext(AuthenticationContext).uid;
export const useSignInEmail = () => useContext(AuthenticationContext).signInEmail;
export const useIsSignInEmailVerified = () => useContext(AuthenticationContext).emailVerified;
export const useIsMasterAdmin = () => useContext(AuthenticationContext).realmMaster;
export const useRegisteredDeviceId = () => useContext(AuthenticationContext).registeredDeviceId;
export const useIsRequireSignIn = () => useContext(AuthenticationContext).requireSignIn;
export const useIsPlatformMode = () => useContext(AuthenticationContext).platformMode;
export const useInPendingSetup = () => useContext(AuthenticationContext).pendingSetup;
export const useConnectedDeviceId = () => useContext(AuthenticationContext).connectDeviceId;
export const useCheckInStaff = () => useContext(AuthenticationContext).checkInStaff;
export const useAuthSignOut = () => useContext(AuthenticationContext).onSignOut;
