import { useCheckoutBusinessPricing, useCheckoutItems } from "@easybiz/context/src/CheckoutContext";
import { ProductSelectionProvider } from "@easybiz/context/src/ProductSelectionContext";
import useProductOptionsWithSelections from "./useProductOptionsWithSelections";

function CheckoutProductsProvider({ children, flatMode }) {
  const rawPricingData = useCheckoutBusinessPricing();
  const items = useCheckoutItems();
  const pricingData = useProductOptionsWithSelections(rawPricingData, items, flatMode);

  return <ProductSelectionProvider pricingData={pricingData}>{children}</ProductSelectionProvider>;
}

export default CheckoutProductsProvider;
