import { ContainerOutlined } from "@ant-design/icons";
import { useIsCashManagementEnabled, useIsPendingStartDrawer } from "@easybiz/context/src/CashDrawerContext";
import { useDisplayMode, useSetDisplayMode } from "@easybiz/context/src/LiveStateContext";
import { usePrinter } from "@easybiz/hook";
import { DISPLAY_MODE_CASH_DRAWER } from "@easybiz/utils";
import { Badge, Button } from "antd";
import React from "react";
import { useIntl } from "react-intl";

export default () => {
  const printer = usePrinter();
  const pendingStart = useIsPendingStartDrawer();
  const cashManageEnabled = useIsCashManagementEnabled();
  const displayMode = useDisplayMode();
  const setDisplayMode = useSetDisplayMode();
  const intl = useIntl();

  return (
    <Badge count={pendingStart ? intl.formatMessage({ defaultMessage: "Not started" }) : null} offset={[-32, -4]}>
      <Button
        size="large"
        type={displayMode?.key === DISPLAY_MODE_CASH_DRAWER ? "primary" : "default"}
        danger={pendingStart}
        style={{ width: 64 }}
        onClick={() => {
          if (cashManageEnabled) {
            setDisplayMode(displayMode?.key === DISPLAY_MODE_CASH_DRAWER ? null : { key: DISPLAY_MODE_CASH_DRAWER });
          } else {
            printer.openDrawer();
          }
        }}
        icon={<ContainerOutlined />}
      />
    </Badge>
  );
};
