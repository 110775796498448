import { CreditCardFilled } from "@ant-design/icons";
import { DateDisplay, NameDisplay } from "@easybiz/component";
import { useBusinessCode } from "@easybiz/context/src/ConfigurationContext";
import { useIsPOSDayClosed } from "@easybiz/context/src/POSContext";
import { useUnpaidOrdersPanelState } from "@easybiz/pos";
import { ACTION_PAYMENT } from "@easybiz/utils";
import { OrderList, PaymentDrawer, PreviewUnpaidInvoiceButton } from "@easybiz/web-admin";
import { useOrderStateQuery } from "@easybiz/web-firebase";
import { Button, Checkbox, Col, message, Row, Space, Typography } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function MainActionUnpaidOrdersPanel({ customer, outstandingCount, onPreviewInvoice }) {
  const businessCode = useBusinessCode();
  const [orders] = useOrderStateQuery({
    businessCode,
    awaitAction: ACTION_PAYMENT,
    limit: 200,
    customerId: customer.id,
  });
  const { totalSelectedBalance, allChecked, months, orderIds, onSelect, onSelectAll, onClear } =
    useUnpaidOrdersPanelState(orders);
  const [batchPayment, setBatchPayment] = useState();
  const intl = useIntl();
  const dayClosed = useIsPOSDayClosed();

  return (
    <div className="flex flex-fill scroll-container">
      <div className="flex flex-fill scroll-body padding-half">
        <Typography.Title level={5}>
          <FormattedMessage
            defaultMessage={"{customerName}'s outstanding orders"}
            values={{
              customerName: customer?.name,
            }}
          />
        </Typography.Title>
        {months?.length > 0 && (
          <Space wrap size="large" className="padding-bottom-half">
            <Checkbox checked={allChecked} indeterminate={orderIds?.length > 0 && !allChecked} onChange={onSelectAll}>
              {<FormattedMessage tagName="span" defaultMessage="All" />}({orders ? orders.length : 0})
            </Checkbox>
            {months.map(({ month, checked, indeterminate, count, onChange }) => (
              <Checkbox key={month} checked={checked} indeterminate={indeterminate} onChange={onChange}>
                <DateDisplay date={`${month}-01`} hideDay />({count})
              </Checkbox>
            ))}
          </Space>
        )}
        <OrderList
          loading={!orders}
          orders={orders}
          selects={orderIds}
          onSelect={onSelect}
          mutipleSelect
          emptyText={
            outstandingCount > 0 ? (
              <FormattedMessage
                defaultMessage={"Customer's other {count} outstanding orders are coming from other store/App"}
                values={{
                  count: outstandingCount,
                }}
              />
            ) : (
              <FormattedMessage
                defaultMessage={"Customer do not have outstanding orders under store {storeName}"}
                values={{
                  storeName: <NameDisplay nameKey={businessCode} />,
                }}
              />
            )
          }
        />
      </div>
      <Row gutter={[8, 8]} className="padding-half">
        <Col span={12}>
          <PreviewUnpaidInvoiceButton
            orderIds={orderIds}
            businessCode={businessCode}
            customer={customer}
            onPreviewLoaded={onPreviewInvoice}
          />
        </Col>
        <Col span={12}>
          <Button
            size="large"
            type={orderIds?.length ? "primary" : "default"}
            onClick={() => {
              if (!orderIds || orderIds.length === 0) {
                return message.info(intl.formatMessage({ defaultMessage: "Please select orders" }));
              }

              setBatchPayment({
                fullAmount: totalSelectedBalance,
                orderIds,
              });
            }}
            disabled={dayClosed}
            icon={<CreditCardFilled />}
            block
          >
            <FormattedMessage tagName="span" defaultMessage="Batch payment" />
            {orderIds?.length ? `(${orderIds.length})` : null}
          </Button>
        </Col>
      </Row>
      <PaymentDrawer
        orderIds={batchPayment?.orderIds}
        fullAmount={batchPayment?.fullAmount}
        open={Boolean(batchPayment)}
        customer={customer}
        onCancel={() => setBatchPayment(null)}
        onSucceed={() => {
          onClear();
          setBatchPayment(null);
        }}
      />
    </div>
  );
}
