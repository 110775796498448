"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { formatAccessory } from "@easybiz/utils";
import { collection, getFirestore, onSnapshot, orderBy, query } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (serviceId, formatData) => {
  const realmId = useRealmId();
  const [accessories, setAccessories] = useState(null);

  useEffect(() => {
    setAccessories(null);
    if (realmId && serviceId) {
      return onSnapshot(
        query(collection(getFirestore(), `realms/${realmId}/services/${serviceId}/accessories`), orderBy("sortIndex")),
        (result) => setAccessories(formatData ? result.docs.map(formatAccessory) : result.docs)
      );
    }
  }, [realmId, serviceId]);

  return accessories;
};
