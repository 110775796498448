"use client";

import { useDisplayName } from "@easybiz/context/src/NamingContext";
import { BUSINESS_ID_FACTORY } from "@easybiz/utils";
import React from "react";
import { FormattedMessage } from "react-intl";

function NameDisplay({ nameKey, showKey }) {
  const businessName = useDisplayName(nameKey);

  if (nameKey === BUSINESS_ID_FACTORY) {
    return <FormattedMessage id="plant" defaultMessage={"Plant"} />;
  } else {
    return businessName || (showKey ? nameKey || null : null);
  }
}

export default NameDisplay;
