"use client";

import { doc, getFirestore, onSnapshot } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (realmId, businessCode) => {
  const [businessDoc, setBusinessDoc] = useState();

  useEffect(() => {
    setBusinessDoc(null);
    if (realmId && businessCode) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/businesses/${businessCode}`),
        setBusinessDoc
      );
    }
  }, [realmId, businessCode]);

  return businessDoc;
};
