import { HistoryOutlined } from "@ant-design/icons";
import { CustomerDisplay, IconButton } from "@easybiz/web-admin";
import { DateDisplay, OrderNoDisplay } from "@easybiz/component";
import { useBusinessCode } from "@easybiz/context/src/ConfigurationContext";
import { escapeRegExp, PROCEDURE_STORE_CHECK_IN, PROCEDURE_STORE_CHECK_OUT, searchOrder } from "@easybiz/utils";
import { Input, Table } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import HandoverButton from "./HandoverButton";

export default ({ loading, serviceId, orders, isCheckOut, onComplete, onSelect, onHistory }) => {
  const [selectOrders, setSelectOrders] = useState([]);
  const [search, setSearch] = useState("");
  const intel = useIntl();
  const businessCode = useBusinessCode();

  let dataSource = orders;
  if (search && dataSource) {
    const regX = new RegExp(escapeRegExp(search), "i");
    dataSource = dataSource.filter((order) => searchOrder(regX, order));
  }

  let dates = dataSource.map((data) => data.get(isCheckOut ? "date" : `delivery.date`));
  dates = dates.filter((date, index) => dates.indexOf(date) === index);

  return (
    <React.Fragment>
      <div className="flex flex-row padding-half">
        <div className="flex flex-fill">
          <Input.Search
            placeholder={intel.formatMessage({ defaultMessage: "Search" })}
            value={search}
            size="large"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="padding-horizontal-half">
          <IconButton icon={<HistoryOutlined />} onClick={onHistory} />
        </div>
        <HandoverButton
          serviceId={serviceId}
          orders={selectOrders}
          procedure={isCheckOut ? PROCEDURE_STORE_CHECK_OUT : PROCEDURE_STORE_CHECK_IN}
          onComplete={(receipeLines) => {
            setSelectOrders([]);
            onComplete(receipeLines);
          }}
        />
      </div>
      <Table
        rowKey="id"
        loading={!!loading}
        dataSource={dataSource || []}
        size="large"
        pagination={dataSource && { pageSize: 100, total: dataSource.length, showSizeChanger: false }}
        scroll={{ y: window.innerHeight - 330 }}
        columns={[
          {
            title: isCheckOut ? (
              <FormattedMessage tagName="span" defaultMessage="Receiving" />
            ) : (
              <FormattedMessage tagName="span" defaultMessage="Collection" />
            ),
            key: "date",
            filters: dates.map((date) => ({ text: date, value: date })),
            onFilter: (value, record) => record.get(isCheckOut ? "date" : "delivery.date") === value,
            width: "25%",
            render: (order) => <DateDisplay date={order.get(isCheckOut ? "date" : "delivery.date")} />,
          },
          {
            title: <FormattedMessage tagName="span" defaultMessage="Order No" />,
            key: "no",
            width: "25%",
            render: (order) =>
              order.get("business.id") === businessCode ? (
                `#${order.get("orderNo")}`
              ) : (
                <OrderNoDisplay orderNo={order.get("orderNo")} business={order.get("business")} />
              ),
          },
          {
            title: <FormattedMessage tagName="span" defaultMessage="Customer" />,
            key: "cus",
            width: "25%",
            render: (order) => <CustomerDisplay customer={order.get("customer")} />,
          },
          {
            title: (
              <FormattedMessage
                tagName="span"
                defaultMessage="Pcs(unit)"
                description="Total pcs count and total unit count"
              />
            ),
            key: "pcs",
            width: "25%",
            render: (order) =>
              `${order.get("totalPcs")}${
                order.get("totalPcd") !== order.get("totalUnit") ? `(${order.get("totalUnit")})` : ""
              }`,
          },
        ]}
        rowSelection={{
          selectedRowKeys: selectOrders && selectOrders.map((order) => order.id),
          onChange: (selectedRowKeys) =>
            setSelectOrders(
              selectedRowKeys ? dataSource.filter((order) => selectedRowKeys.indexOf(order.id) >= 0) : []
            ),
        }}
        onRow={(order) => ({ onClick: () => onSelect(order) })}
        rowClassName="selectable"
      />
    </React.Fragment>
  );
};
