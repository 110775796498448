import { PrinterOutlined, WalletOutlined } from "@ant-design/icons";
import { AmountDisplay, DateDisplay, TransactionTypeDisplay } from "@easybiz/component";
import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { TRANSACTION_TYPE_TOP_UP } from "@easybiz/utils";
import { LoadMoreButton, ReceiptView, Title } from "@easybiz/web-admin";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "@firebase/firestore";
import { Drawer, List, message, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default ({ customer, open, onCancel, placement }) => {
  const realmId = useRealmId();
  const [loading, setLoading] = useState(false);
  const [receipt, setReceipt] = useState();
  const [loadingId, setLoadingId] = useState();
  const [transactions, setTransactions] = useState();
  const intl = useIntl();

  const onLoad = (reset) => {
    setLoading(true);

    let operation = query(
      collection(getFirestore(), `realms/${realmId}/transactions`),
      where("customer.id", "==", customer?.id),
      orderBy("createDate.raw", "desc"),
      limit(10)
    );

    if (reset !== true && Array.isArray(transactions) && transactions[0]) {
      operation = query(operation, startAfter(transactions[transactions.length - 1]));
    }

    getDocs(operation)
      .then((result) => {
        if (reset === true) {
          setTransactions(result.docs);
        } else {
          if (result.empty) {
            message.info(
              intl.formatMessage({
                defaultMessage: "All credit history loaded",
              })
            );
          } else {
            setTransactions([...(transactions || []), ...result.docs]);
          }
        }
      })
      .catch((err) => message.error(err.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (open) {
      onLoad(true);
    } else {
      setTransactions(null);
    }
  }, [open]);

  return (
    <Drawer
      title={
        <FormattedMessage
          tagName="span"
          defaultMessage={`Credit History ({name})`}
          values={{ name: customer && customer.name }}
        />
      }
      open={open}
      onClose={onCancel}
      placement={placement}
      width={470}
      bodyStyle={{ padding: 0 }}
    >
      <List
        dataSource={transactions || []}
        size="small"
        loading={!transactions}
        renderItem={(transaction) => {
          const { type, operator, createDate, amount, creditAfter, error, remarks } = transaction.data();
          const isMinus = amount < 0;
          const selectable = type === TRANSACTION_TYPE_TOP_UP;

          return (
            <List.Item
              extra={
                <div className="flex flex-align-end">
                  <Title type={isMinus ? "danger" : "success"}>
                    {isMinus ? "-" : "+"}
                    <AmountDisplay value={Math.abs(amount)} />
                  </Title>
                  <Typography.Text>
                    <WalletOutlined /> <AmountDisplay value={creditAfter} />
                  </Typography.Text>
                </div>
              }
              className={selectable ? "selectable" : null}
              onClick={async () => {
                if (selectable) {
                  try {
                    setLoadingId(transaction.id);

                    const receipt = await getDoc(
                      doc(getFirestore(), `realms/${realmId}/topup_receipts/${transaction.id}`)
                    );
                    if (receipt.exists()) {
                      setReceipt(receipt.get("lines"));
                    } else {
                      message.error(
                        intl.formatMessage({
                          defaultMessage: "Top up receipt not found",
                        })
                      );
                    }
                  } catch (err) {
                    message.error(err.message);
                  } finally {
                    setLoadingId(null);
                  }
                }
              }}
            >
              <List.Item.Meta
                title={
                  <span>
                    <b>
                      <TransactionTypeDisplay type={type} />
                    </b>
                    {remarks ? ` (${remarks})` : null} {selectable ? <PrinterOutlined /> : null}
                  </span>
                }
                description={
                  <div>
                    {error && (
                      <div>
                        <Typography.Text type="danger">{error}</Typography.Text>
                      </div>
                    )}
                    <div>
                      {operator.name} <DateDisplay {...createDate} />
                    </div>
                  </div>
                }
              />
              {loadingId === transaction.id && <Spin spinning />}
            </List.Item>
          );
        }}
        footer={<LoadMoreButton loading={loading} dataSource={transactions} onClick={onLoad} />}
      />
      <Drawer
        placement={placement}
        title={<FormattedMessage tagName="span" defaultMessage="Top Up Receipt" />}
        open={Boolean(receipt)}
        onClose={() => setReceipt(null)}
        width={470}
      >
        {receipt ? <ReceiptView lines={receipt} printing /> : <List loading />}
      </Drawer>
    </Drawer>
  );
};
