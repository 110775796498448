import { usePOSBatchHandover } from "@easybiz/api-pos";
import { PROCEDURE_STORE_CHECK_OUT } from "@easybiz/utils";
import { Button } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default ({ serviceId, orders, procedure, onComplete }) => {
  const { onSubmit, loading } = usePOSBatchHandover((succeed, response) => {
    if (succeed) {
      onComplete(response.lines);
    }
  });

  return (
    <Button
      loading={loading}
      type={orders?.length > 0 ? "primary" : "default"}
      onClick={() =>
        onSubmit({
          procedure,
          serviceId,
          orderIds: (orders || []).map((order) => order.id),
        })
      }
    >
      {procedure === PROCEDURE_STORE_CHECK_OUT ? (
        <FormattedMessage tagName="span" defaultMessage="Check out" />
      ) : (
        <FormattedMessage tagName="span" defaultMessage="Check in" />
      )}
      {orders.length ? ` (${orders.length})` : null}
    </Button>
  );
};
