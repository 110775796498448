import { CheckoutProductsProvider } from "@easybiz/component";
import { useBusinessCode } from "@easybiz/context/src/ConfigurationContext";
import {
  useCheckoutCustomer,
  useCheckoutParams,
  useCheckoutService,
  useUpdateCheckoutParams,
} from "@easybiz/context/src/CheckoutContext";
import { useDisplayMode, useSetDisplayMode } from "@easybiz/context/src/LiveStateContext";
import {
  DISPLAY_MODE_CHECK_IN,
  DISPLAY_MODE_CHECK_OUT,
  DISPLAY_MODE_COLLECTION,
  DISPLAY_MODE_CONFIRM_ORDER,
  DISPLAY_MODE_PRODUCTION,
  DISPLAY_MODE_REDO,
  DISPLAY_MODE_SALES,
  DISPLAY_MODE_SCHEDULE,
} from "@easybiz/utils";
import { GridSelectionPanel } from "@easybiz/web-admin";
import React, { useCallback, useState } from "react";
import CheckInOutHandoverPanel from "./CheckInOutHandoverPanel";
import CollectionDeliverySchedulePanel from "./CollectionDeliverySchedulePanel";
import CollectionMonitorPanel from "./CollectionMonitorPanel";
import InvoicePanel from "./InvoicePanel";
import POSDaySalesPanel from "./POSDaySalesPanel";
import POSMainHeader from "./POSMainHeader";
import POSProductionMonitorPanel from "./POSProductionMonitorPanel";
import PendingPriceConfirmPanel from "./PendingPriceConfirmPanel";
import RedoItemsSelectionPanel from "./RedoItemsSelectionPanel";

export default function POSHomeLeftPanel() {
  const [search, setSearch] = useState();
  const { address, returnSchedule, collectionPlace } = useCheckoutParams();
  const updateCheckout = useUpdateCheckoutParams();
  const service = useCheckoutService();
  const businessCode = useBusinessCode();
  const customer = useCheckoutCustomer();
  const displayMode = useDisplayMode();
  const setDisplayMode = useSetDisplayMode();

  const onClose = useCallback(() => {
    setDisplayMode(null);
  }, [setDisplayMode]);

  switch (displayMode?.key) {
    case DISPLAY_MODE_SALES:
      return <POSDaySalesPanel date={displayMode?.date} selectOrderId={displayMode?.orderId} onClose={onClose} />;
    case DISPLAY_MODE_CONFIRM_ORDER:
      return <PendingPriceConfirmPanel onClose={onClose} />;
    case DISPLAY_MODE_CHECK_IN:
      return <CheckInOutHandoverPanel isCheckOut={false} onClose={onClose} />;
    case DISPLAY_MODE_CHECK_OUT:
      return <CheckInOutHandoverPanel isCheckOut={true} onClose={onClose} />;
    case DISPLAY_MODE_COLLECTION:
      return <CollectionMonitorPanel date={displayMode?.date} onClose={onClose} />;
    case DISPLAY_MODE_PRODUCTION:
      return <POSProductionMonitorPanel date={displayMode?.date?.format("YYYY-MM-DD")} onClose={onClose} />;
    case DISPLAY_MODE_SCHEDULE:
      return (
        <CollectionDeliverySchedulePanel
          service={service}
          businessCode={businessCode}
          customer={customer}
          address={address}
          returnSchedule={returnSchedule}
          collectionPlace={collectionPlace}
          onClose={onClose}
          onUpdate={(updates) => {
            updateCheckout(updates);
            onClose(false);
          }}
        />
      );
    case DISPLAY_MODE_REDO:
      if (displayMode.scheduleOpen) {
        return (
          <CollectionDeliverySchedulePanel
            service={displayMode.order.get("service")}
            businessCode={displayMode.order.get("business.id")}
            customer={displayMode.order.get("customer")}
            address={displayMode.address}
            returnSchedule={displayMode.returnSchedule}
            collectionPlace={displayMode.collectionPlace}
            onClose={() => setDisplayMode({ ...displayMode, scheduleOpen: false })}
            onUpdate={(updates) => {
              setDisplayMode({ ...displayMode, ...updates, scheduleOpen: false });
            }}
          />
        );
      } else {
        return <RedoItemsSelectionPanel onClose={onClose} />;
      }
    default:
      if (displayMode?.invoice) {
        return <InvoicePanel invoice={displayMode?.invoice} onClose={onClose} setDisplayMode={setDisplayMode} />;
      } else {
        return (
          <>
            <POSMainHeader search={search} onSearch={setSearch} />
            <CheckoutProductsProvider>
              <GridSelectionPanel search={search} onSearch={setSearch} />
            </CheckoutProductsProvider>
          </>
        );
      }
  }
}
