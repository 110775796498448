import {
  useCheckoutAdjustments,
  useCheckoutEditOrder,
  useCheckoutParams,
  useCheckoutPricing,
  useCheckoutService,
  useCheckoutEditItem,
  useSetCheckoutEditItem,
  useTogglePromotion,
  useUpdateCheckoutParams,
} from "@easybiz/context/src/CheckoutContext";
import { Input, Space } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import DiscountSurchargePanel from "../DiscountSurchargePanel";
import OrderPricingList from "../OrderPricingList";

export default function ({ header, disabled }) {
  const pricing = useCheckoutPricing();
  const setEditItem = useSetCheckoutEditItem();
  const item = useCheckoutEditItem();
  const editOrder = useCheckoutEditOrder();
  const onTogglePromotion = useTogglePromotion();
  const service = useCheckoutService();
  const adjustments = useCheckoutAdjustments();
  const updateCheckout = useUpdateCheckoutParams();
  const { salesRemark } = useCheckoutParams();
  const intl = useIntl();

  return (
    <div className="flex flex-fill scroll-body">
      <Space direction="vertical" className="full-width" size={"middle"}>
        <OrderPricingList
          header={header}
          editOrder={editOrder}
          pricing={pricing}
          disabled={disabled}
          onSelect={setEditItem}
          editCartIndex={item?.itemIndex}
          showDisabledPromo
          onTogglePromotion={onTogglePromotion}
          onAdjustmentChange={(adjustment) => {
            const index = Array.isArray(adjustments) ? adjustments.findIndex((item) => item.id === adjustment.id) : -1;
            if (index >= 0) {
              if (typeof adjustment.value === "number" && adjustment.value > 0) {
                updateCheckout({
                  adjustments: [...adjustments.slice(0, index), adjustment, ...adjustments.slice(index + 1)],
                });
              } else {
                updateCheckout({
                  adjustments: [...adjustments.slice(0, index), ...adjustments.slice(index + 1)],
                });
              }
            }
          }}
          onScheduleChange={(returnSchedule) => updateCheckout({ returnSchedule })}
        />
        <Space direction="vertical" className="full-width padding-half">
          <DiscountSurchargePanel
            serviceId={service?.id}
            disabled={disabled}
            selects={adjustments}
            onChange={(adjustments) => {
              updateCheckout({ adjustments });
            }}
            onPromoCodeApplied={(promoCode) => updateCheckout({ promoCode })}
          />
          <Input.TextArea
            disabled={disabled}
            placeholder={intl.formatMessage({ defaultMessage: "Order note" })}
            value={salesRemark}
            onChange={(e) => updateCheckout({ salesRemark: e.target.value })}
          />
        </Space>
      </Space>
    </div>
  );
}
