"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { formatLocalAddresses } from "@easybiz/utils";
import { collection, getDocs, getFirestore, limit, query, where } from "@firebase/firestore/lite";
import { useEffect, useState } from "react";

export default (city, search) => {
  const realmId = useRealmId();
  const [addresses, setAddresses] = useState(null);

  useEffect(() => {
    setAddresses(null);
    if (city && search && !isNaN(search)) {
      getDocs(
        query(
          collection(getFirestore(), `cities/${`${city}`.toLowerCase()}/addresses`.toLowerCase()),
          where("searchables", "array-contains", `${search}`.toLowerCase()),
          limit(5)
        )
      ).then((result) => setAddresses(formatLocalAddresses(result.docs)));
    }
  }, [realmId, city, search]);

  return addresses;
};
