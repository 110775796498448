import { EditOutlined } from "@ant-design/icons";
import { PROMO_TYPE_PERCENT } from "@easybiz/utils";
import { Button } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import PricingOverviewItemBase from "../PricingOverviewItemBase";
import QtyInputPopover from "../QtyInputPopover";
import { useCurrency } from "@easybiz/context/src/ConfigurationContext";

export default ({ delivery, amount, onChange, disabled }) => {
  const currency = useCurrency();
  const isPercentage = delivery?.option?.surcharge?.type === PROMO_TYPE_PERCENT;
  const expressRate = isPercentage ? delivery?.option?.surcharge?.value : null;
  const [open, setVisible] = useState(false);

  return (
    <PricingOverviewItemBase
      title={
        <span>
          <FormattedMessage defaultMessage="Express surcharge" />
          {typeof expressRate === "number" ? ` (${expressRate}%)` : ""}
        </span>
      }
      amount={amount}
      extra={
        onChange && (
          <QtyInputPopover
            removeable
            open={open}
            onOpenChange={setVisible}
            title={<FormattedMessage defaultMessage="Express surcharge" />}
            placement={"left"}
            value={delivery?.option?.surcharge?.value}
            onDone={(update) => {
              setVisible(false);
              onChange({
                ...delivery,
                option: {
                  ...delivery?.option,
                  surcharge:
                    typeof update === "number" && update > 0
                      ? { ...delivery?.option?.surcharge, value: update }
                      : null,
                },
              });
            }}
            suffix={isPercentage ? "%" : null}
            prefix={isPercentage ? null : currency}
          >
            <Button size="small" type="link" icon={<EditOutlined />} disabled={disabled}>
              <FormattedMessage tagName={"span"} defaultMessage={"Edit"} />
            </Button>
          </QtyInputPopover>
        )
      }
    />
  );
};
