import { deleteField, updateDoc } from "@firebase/firestore";
import React, { useEffect, useState } from "react";
import SystemNoticeDialog from "../SystemNoticeDialog";
import { useRegisteredDevice } from "@easybiz/context/src/DeviceRegistrationContext";

export default () => {
  const registeredDevice = useRegisteredDevice();
  const [notice, setNotice] = useState();

  useEffect(() => {
    if (registeredDevice?.get("notice")) {
      setNotice(registeredDevice?.get("notice"));
      updateDoc(registeredDevice.ref, { notice: deleteField() });
    }
  }, [Boolean(registeredDevice?.get("notice"))]);

  return (
    <SystemNoticeDialog
      notice={notice}
      open={Boolean(notice)}
      onCancel={() => setNotice(null)}
    />
  );
};
