import { useClientType, usePlatform } from "@easybiz/context/src/ConfigurationContext";
import { useActionCallback } from "@easybiz/hook";
import { ADMIN_ACTION_REGISTER_DEVICE, OPERATION_GROUP_ADMIN } from "@easybiz/utils";
import { useCallback } from "react";

function useAdminDeviceRegister(callback) {
  const platform = usePlatform();
  const client = useClientType();

  const onSubmit = useCallback(
    (deviceInfo) => {
      return [OPERATION_GROUP_ADMIN, ADMIN_ACTION_REGISTER_DEVICE, { ...deviceInfo, platform, client }];
    },
    [platform, client]
  );

  return useActionCallback(onSubmit, callback);
}

export default useAdminDeviceRegister;
