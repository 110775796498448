"use client";

import { useAuthUser } from "@easybiz/context/src/AuthenticationContext";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

function useApplicationLauncher() {
  const [showReload, setShowReload] = useState(false);
  const user = useAuthUser();

  useEffect(() => {
    if (user) {
      const handler = setTimeout(() => {
        setShowReload(true);
      }, 10000);

      return () => clearTimeout(handler);
    }
  }, [user]);

  return {
    title: user ? (
      <FormattedMessage id="getting.everything.ready" defaultMessage={"Getting everything ready..."} />
    ) : (
      <FormattedMessage id="logging.in" defaultMessage={"Logging in..."} />
    ),
    showReload,
  };
}

export default useApplicationLauncher;
