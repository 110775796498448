// Item collection
export const PROCEDURE_PENDING_COLLECTION = 'PENDING COLLECTION';
export const PROCEDURE_DRIVER_COLLECTED = 'DRIVER COLLECTED';
export const PROCEDURE_STORE_RECEIVED = 'SHOP RECEIVED';
export const PROCEDURE_STORE_CHECK_OUT = 'SHOP COLLECTED';
export const PROCEDURE_STORE_CHECK_IN = 'SHOP DELIVERED';

// Item processing
export const PROCEDURE_PLANT_CHECK_IN = 'FACTORY RECEIVED';
export const PROCEDURE_PLANT_CONFIRM_ORDER = 'FACTORY CONFIRM';
export const PROCEDURE_PLANT_LABELING = 'TAGGED';
export const PROCEDURE_PLANT_SERVICING = 'WASHING';
export const PROCEDURE_PRODUCTION_CALENDAR = 'PRODUCTION CALENDAR';
export const PROCEDURE_PLANT_SERVICE_COMPLETE = 'BAGGING';
export const PROCEDURE_PLANT_PACKING = 'PACKED';
export const PROCEDURE_STORAGE = 'STORAGE';
export const PROCEDURE_PLANT_CHECK_OUT = 'FACTORY COLLECTED';
export const PROCEDURE_DISCHARGED = 'CUSTOMER DELIVERED';

export const PLANT_PROCEDURES = [
  PROCEDURE_PLANT_CHECK_IN,
  PROCEDURE_PLANT_CONFIRM_ORDER,
  PROCEDURE_PLANT_LABELING,
  PROCEDURE_PLANT_SERVICING,
  PROCEDURE_PLANT_SERVICE_COMPLETE,
  PROCEDURE_PLANT_PACKING,
  PROCEDURE_PLANT_CHECK_OUT,
  PROCEDURE_DISCHARGED
];

export const PROCEDURES = [
  PROCEDURE_PENDING_COLLECTION,
  PROCEDURE_DRIVER_COLLECTED,
  PROCEDURE_STORE_RECEIVED,
  PROCEDURE_STORE_CHECK_OUT,
  PROCEDURE_PLANT_CHECK_IN,
  PROCEDURE_PLANT_CONFIRM_ORDER,
  PROCEDURE_PLANT_LABELING,
  PROCEDURE_PLANT_SERVICING,
  PROCEDURE_PLANT_SERVICE_COMPLETE,
  PROCEDURE_PLANT_PACKING,
  PROCEDURE_PLANT_CHECK_OUT,
  PROCEDURE_STORE_CHECK_IN,
  PROCEDURE_DISCHARGED,
];

export const ACTION_DEPOSIT = 'deposit';
export const ACTION_SCHEDULE_PICKUP = 'schedule_pickup';
export const ACTION_SCHEDULE_DELIVER = 'schedule_deliver';
export const ACTION_CONFIRM_PRICE = 'confirm_price';
export const ACTION_ASSIGN = 'assign';
export const ACTION_SERVICE = 'service';
export const ACTION_SUB_SERVICE = 'sub_service';
export const ACTION_PACK = 'pack';
export const ACTION_PAYMENT = 'payment';
export const ACTION_PICK_UP = 'pickup';
export const ACTION_DELIVER = 'deliver';
export const ACTION_COLLECT = 'collect';
export const ACTION_STORE_OUT = 'store_out';
export const ACTION_STORE_IN = 'store_in';
export const ACTION_PLANT_OUT = 'plant_out';
export const ACTION_PLANT_IN = 'plant_in';
// Use for customer reconfirmation only
export const ACTION_AGREE_PRICE = 'agree_price';
export const ACTION_ACK_PRICE = 'ack_price';
// Internal action
export const ACTION_COMPLETE = 'complete';
export const ACTION_CANCEL = 'cancel';

export const GENERAL_ACTION_ORDERS = [
  ACTION_STORE_OUT,
  ACTION_SCHEDULE_PICKUP,
  ACTION_PICK_UP,
  ACTION_PLANT_IN,
  ACTION_CONFIRM_PRICE,
  ACTION_AGREE_PRICE,
  ACTION_PAYMENT,
  ACTION_ACK_PRICE,
  ACTION_ASSIGN,
  ACTION_SERVICE,
  ACTION_PACK,
  ACTION_SCHEDULE_DELIVER,
  ACTION_PLANT_OUT,
  ACTION_DELIVER,
  ACTION_STORE_IN,
  ACTION_COLLECT
];