"use client";

import { formatPrice } from "@easybiz/utils";
import { useEffect, useMemo, useState } from "react";
import { useCheckoutProduct } from "@easybiz/context/src/ProductSelectionContext";

function useStandardLineItemSelectPanel(productId, subTypeId, selected, alwaysShowSubType) {
  // TODO: Change all to follow alwaysShowSubType
  const [selectSubTypeId, setSelectSubTypeId] = useState(subTypeId || null);
  const product = useCheckoutProduct(productId);

  useEffect(() => {
    // Only follow right panel selection state if the current item is selected
    if (selected) {
      setSelectSubTypeId(subTypeId || null);
    }
  }, [subTypeId, selected]);

  return useMemo(() => {
    const { title, photoId, options } = product || {};

    let subTypes = [],
      productQty = 0,
      productPcs = 0;

    options?.forEach(({ prices }) => {
      prices?.forEach(({ subType, subTypeId, photoId, qty, pcs }) => {
        if (subTypeId) {
          const index = subTypes.findIndex((added) => added.id == subTypeId);
          if (index < 0) {
            subTypes.push({ id: subTypeId, title: subType, photoId, qty: qty || 0, pcs: pcs || 0 });
          } else if (typeof qty === "number") {
            subTypes[index].qty = formatPrice(subTypes[index].qty + qty);
            if (Number.isInteger(pcs)) {
              subTypes[index].pcs = formatPrice(subTypes[index].pcs + pcs);
            }
          }
        } else if (typeof qty === "number") {
          productQty = formatPrice(productQty + qty);
          if (Number.isInteger(pcs)) {
            productPcs = formatPrice(productPcs + pcs);
          }
        }
      });
    });

    if (subTypes.length > 0 || alwaysShowSubType) {
      subTypes.unshift({
        id: null,
        title,
        photoId,
        qty: productQty,
        pcs: productPcs,
      });
    }

    const subType = selectSubTypeId && subTypes.find((added) => added.id == selectSubTypeId);

    return {
      subTypes: subTypes.length > 0 ? subTypes : null,
      select: subType
        ? {
            ...subType,
            title: `${title} - ${subType.title}`,
          }
        : {
            id: null, // Main product, no sub ID
            title,
            photoId,
            qty: productQty,
          },
      prices: [].concat.apply(
        [],
        (options || []).map(({ prices }) =>
          (prices || []).filter((price) => (selectSubTypeId ? selectSubTypeId == price.subTypeId : !price.subTypeId))
        )
      ),
      setSelectSubTypeId,
    };
  }, [product, selectSubTypeId, alwaysShowSubType]);
}

export default useStandardLineItemSelectPanel;
