export const PLACEHOLDER_BUSINESS_CODE = "BUSINESS_CODE";
export const PLACEHOLDER_ORDER_NO = "ORDER_NO";
export const PLACEHOLDER_ORDER_TOTAL_UNIT = "ORDER_TOTAL_PCS";
export const PLACEHOLDER_ORDER_UNIT_INDEX = "ORDER_PCS_INDEX";
export const PLACEHOLDER_SET_TOTAL_PCS = "SET_TOTAL_PCS";
export const PLACEHOLDER_SET_PCS_INDEX = "SET_PCS_INDEX";
export const PLACEHOLDER_CUSTOMER_NAME = "CUSTOMER_NAME";
export const PLACEHOLDER_CUSTOMER_CONTACT = "CUSTOMER_CONTACT";
export const PLACEHOLDER_AGENT_NAME = "AGENT_NAME";
export const PLACEHOLDER_ITEM_NAME = "ITEM_NAME";
export const PLACEHOLDER_ITEM_DEPARTMENT = "ITEM_DEPARTMENT";
export const PLACEHOLDER_SERVICE_OPTION = "SERVICE_OPTION";
export const PLACEHOLDER_RECEVING_DATE = "RECEIVING_DATE";
export const PLACEHOLDER_PACKING_DATE = "PACKING_DATE";
export const PLACEHOLDER_RECEVING_WEEK_DAY = "RECEVING_WEEK_DAY";
export const PLACEHOLDER_DELIVERY_DATE = "DELIVERY_DATE";
export const PLACEHOLDER_DELIVERY_WEEK_DAY = "DELIVERY_WEEK_DAY";
export const PLACEHOLDER_BALANCE_AMOUNT = "BALANCE_AMOUNT";
export const PLACEHOLDER_ITEM_REMARK = "ITEM_REMARK";
export const PLACEHOLDER_ITEM_DETAILS = "ITEM_DETAILS";
export const PLACEHOLDER_ICONS = "ICONS";
export const PLACEHOLDER_TOTAL_PACKAGE = "TOTAL_PACKAGES";
export const PLACEHOLDER_PACKAGE_INDEX = "PACKAGE_INDEX";
export const PLACEHOLDER_DEPARTMENT = "DEPARTMENT";
export const PLACEHOLDER_PACKING_PCS = "PACKING_PCS";
export const PLACEHOLDER_COLLECTION_PLACE = "COLLECTION_PLACE";

export const LABEL_AREA_LEFT = "left";
export const LABEL_AREA_RIGHT = "right";
export const LABEL_AREA_SIDE = "side";

export const LABEL_PLACEHOLDERS = [
  PLACEHOLDER_BUSINESS_CODE,
  PLACEHOLDER_ORDER_NO,
  PLACEHOLDER_ORDER_TOTAL_UNIT,
  PLACEHOLDER_ORDER_UNIT_INDEX,
  PLACEHOLDER_SET_TOTAL_PCS,
  PLACEHOLDER_SET_PCS_INDEX,
  PLACEHOLDER_CUSTOMER_NAME,
  PLACEHOLDER_CUSTOMER_CONTACT,
  PLACEHOLDER_ITEM_NAME,
  PLACEHOLDER_ITEM_DEPARTMENT,
  PLACEHOLDER_SERVICE_OPTION,
  PLACEHOLDER_RECEVING_DATE,
  PLACEHOLDER_RECEVING_WEEK_DAY,
  PLACEHOLDER_DELIVERY_DATE,
  PLACEHOLDER_DELIVERY_WEEK_DAY,
  PLACEHOLDER_BALANCE_AMOUNT,
  PLACEHOLDER_ITEM_REMARK,
  PLACEHOLDER_ITEM_DETAILS,
  PLACEHOLDER_ICONS,
  PLACEHOLDER_COLLECTION_PLACE,
  // Package
  PLACEHOLDER_AGENT_NAME,
  PLACEHOLDER_PACKING_DATE,
  PLACEHOLDER_PACKAGE_INDEX,
  PLACEHOLDER_TOTAL_PACKAGE,
  PLACEHOLDER_DEPARTMENT,
  PLACEHOLDER_PACKING_PCS,
];

export const PACK_PLACEHOLDERS = [
  PLACEHOLDER_BUSINESS_CODE,
  PLACEHOLDER_ORDER_NO,
  PLACEHOLDER_ORDER_TOTAL_UNIT,
  PLACEHOLDER_CUSTOMER_NAME,
  PLACEHOLDER_CUSTOMER_CONTACT,
  PLACEHOLDER_AGENT_NAME,
  PLACEHOLDER_PACKING_DATE,
  PLACEHOLDER_PACKAGE_INDEX,
  PLACEHOLDER_TOTAL_PACKAGE,
  PLACEHOLDER_DEPARTMENT,
  PLACEHOLDER_PACKING_PCS,
];

export const RECEIVING_DAY_INDEX = "RECEIVING_DAY_INDEX";
export const AGENT_SORTING_CODE = "AGENT_SORTING_CODE";
export const INTERNAL_NAME = "INTERNAL_NAME";
