"use client";

import { BUSINESS_ID_FACTORY, toDisplayName } from "@easybiz/utils";
import { createContext, useContext, useMemo } from "react";

const NamingContext = createContext();

export function NamingProvider({ realmDoc, children }) {
  const context = useMemo(() => {
    return {
      serviceTypes: realmDoc?.get("serviceTypes"),
      businessCodes: realmDoc?.get("businessCodes"),
      accounts: realmDoc?.get("accounts"),
      serviceNames: realmDoc?.get("serviceNames"),
      stores: realmDoc?.get("stores"),
      webapps: realmDoc?.get("webapps"),
      paymentMethods: realmDoc?.get("paymentMethods"),
    };
  }, [realmDoc]);

  return <NamingContext.Provider value={context}>{children}</NamingContext.Provider>;
}

export const useNamingContext = () => useContext(NamingContext);

// Realm display
export const useDisplayName = (nameKey, internal) => {
  const nameFields = useContext(NamingContext);
  return toDisplayName(nameKey, nameFields, internal);
};

export const useToDisplayName = () => {
  const nameFields = useContext(NamingContext);

  return (nameKey) => {
    if (nameKey === BUSINESS_ID_FACTORY) {
      return "Plant";
    } else {
      return toDisplayName(nameKey, nameFields);
    }
  };
};

export const useToInternalName = () => {
  const nameFields = useContext(NamingContext);

  return (nameKey) => nameFields?.businessCodes?.[nameKey] || "";
};
