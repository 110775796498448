"use client";

import { useLocale, useStorageHost } from "@easybiz/context/src/ConfigurationContext";
import LocaleDate from "../LocaleDate";

function useOrderHistoryFeeds(historyDoc, orderDoc) {
  const logs = historyDoc?.get("logs");
  const completes = orderDoc?.get("completes");
  const storageHost = useStorageHost();
  const lang = useLocale();

  return {
    dataSource: logs
      ?.sort((a, b) => b.u - a.u)
      .map(({ c, o, u, e, i, uid }) => {
        return {
          key: `${u}`,
          description: c,
          dateTime: <LocaleDate timestamp={u} lang={lang} />,
          operator: o,
          error: Boolean(e),
          complete: completes?.find((action) => action.t === u),
          ...(i && { imageUrl: i }),
          ...(uid && { avatarUrl: `${storageHost}/accounts/${uid}/avatar.png` }),
        };
      }),
  };
}

export default useOrderHistoryFeeds;
