import { useAsyncCollectionOptions } from "@easybiz/component";
import { useBusinessCode } from "@easybiz/context/src/ConfigurationContext";
import {
  useCheckoutEditOrder,
  useCheckoutService,
  useCheckoutServiceTypes,
} from "@easybiz/context/src/CheckoutContext";
import { useClientDate, useSetCollectionOptions } from "@easybiz/context/src/LiveStateContext";
import { useEffect } from "react";

export default function () {
  const service = useCheckoutService();
  const businessCode = useBusinessCode();
  const editOrder = useCheckoutEditOrder();
  const date = useClientDate();
  const serviceTypes = useCheckoutServiceTypes();
  const setCollectionOptions = useSetCollectionOptions();
  const { options } = useAsyncCollectionOptions({
    serviceId: service?.id,
    businessCode,
    date,
    orderId: editOrder?.id,
    serviceTypes,
    combine: true,
  });

  useEffect(() => {
    setCollectionOptions(options);
  }, [options]);
}
