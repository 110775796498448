import { useToast } from "@easybiz/context/src/ConfigurationContext";
import { useActionCallback } from "@easybiz/hook";
import { BUSINESS_ID_FACTORY, OPERATION_GROUP_ORDER, ORDER_ACTION_CREATE, PAYMENT_ID_CASH } from "@easybiz/utils";
import { useIntl } from "react-intl";

function useOrderCreate(callback) {
  const toast = useToast();
  const intl = useIntl();

  const onSubmit = ({ orderId, serviceId, businessCode, source, checkoutParams, pricing, customer }) => {
    const { payment, pricingTBD } = checkoutParams || {};
    if (!customer) {
      return businessCode === BUSINESS_ID_FACTORY
        ? intl.formatMessage({
            id: "please.select.agent",
            defaultMessage: "Please select agent",
          })
        : intl.formatMessage({
            id: "please.select.customer",
            defaultMessage: "Please select customer",
          });
    }
    if (!businessCode)
      return intl.formatMessage({
        id: "please.select.app",
        defaultMessage: "Please select App",
      });
    if (!serviceId)
      return intl.formatMessage({
        id: "please.select.business",
        defaultMessage: "Please select business",
      });
    if (!pricingTBD && pricing?.totalPayable > 0 && payment === undefined) {
      return intl.formatMessage({
        id: "please.select.payment.method",
        defaultMessage: "Please select payment method",
      });
    }

    if (payment?.noteRequired && !payment.note) {
      return intl.formatMessage({
        id: "please.enter.payment.reference",
        defaultMessage: "Please enter payment reference",
      });
    }

    if (payment?.option === PAYMENT_ID_CASH && payment?.cashAmount && payment?.cashAmount < payment.amount) {
      return intl.formatMessage({
        id: "cash.received.must.be.greater.than.the.payment.amount",
        defaultMessage: "Cash received must be greater than the payment amount",
      });
    }

    return [
      OPERATION_GROUP_ORDER,
      ORDER_ACTION_CREATE,
      {
        orderId,
        serviceId,
        businessCode,
        ...(businessCode === BUSINESS_ID_FACTORY ? { agentId: customer.id } : { customerId: customer.id }),
        checkoutParams,
        source,
      },
    ];
  };

  return useActionCallback(onSubmit, callback, (request, response) => {
    if (response.newOrder) {
      toast.success(
        intl.formatMessage(
          {
            id: "order.x.created",
            defaultMessage: "Order {orderNo} created",
          },
          { orderNo: `#${response.newOrder?.orderNo}` }
        )
      );
    }
  });
}

export default useOrderCreate;
