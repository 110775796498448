"use client";

import { useImageHost } from "@easybiz/context/src/ConfigurationContext";
import { useOpenGallery } from "@easybiz/context/src/PhotoGalleryContext";
import { LazyImage } from "@easybiz/web-ui";
import dayjs from "dayjs";
import React, { useMemo } from "react";

function PhotoGallery({ orderId, itemIndex, limit, size, orderOnly, photos }) {
  const setPhotos = useOpenGallery();
  const imageHost = useImageHost();

  const displayPhotos = useMemo(() => {
    return (photos || [])
      .filter((photo) => (orderOnly ? !photo.l : typeof itemIndex !== "number" || itemIndex === photo.l))
      .map((photo) => {
        return {
          id: photo.i,
          url: `${imageHost}/${photo.i}`,
          title: `${dayjs.unix(photo.t).format("DD/MM/YYYY HH:mm")} | ${photo.o}`,
          timestamp: photo.t,
        };
      });
  }, [photos, imageHost]);

  return displayPhotos.map((photo, index) => {
    if (typeof limit === "number" && index >= limit) return null;

    return (
      <div key={photo.url} style={{ width: size || 70 }}>
        <div
          className="selectable"
          onClick={(e) => {
            e.stopPropagation();
            setPhotos({ photos: displayPhotos, viewIndex: index, orderId, itemIndex });
          }}
        >
          <LazyImage src={photo.url} width={size || 70} height={size || 70} />
        </div>
      </div>
    );
  });
}

export default PhotoGallery;
