"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { doc, getFirestore, onSnapshot } from "@firebase/firestore";
import { useEffect, useState } from "react";

export default (orderId, keep) => {
  const realmId = useRealmId();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (!keep) {
      setOrder(null);
    }

    if (realmId && orderId) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}/orders/${orderId}`), setOrder);
    }
  }, [realmId, orderId, keep]);

  return order;
};
