import { AmountDisplay } from "@easybiz/component";
import { formatPrice } from "@easybiz/utils";
import { NumericUpdatePanel } from "@easybiz/web-admin";
import { Button, Drawer, message, Space } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CashInOutPanel from "./CashInOutPanel";
import { useNextDayDepositAmount } from "@easybiz/context/src/CashDrawerContext";

export default ({ cashDrawer, open, isCashIn, onClose }) => {
  const nextDayDeposit = useNextDayDepositAmount();
  const [amount, setAmount] = useState(null);
  const [closeOfDay, setCloseOfDay] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setAmount(null);
    setCloseOfDay(false);
  }, [open]);

  return (
    <Drawer
      title={
        isCashIn ? <FormattedMessage defaultMessage={"Cash In"} /> : <FormattedMessage defaultMessage={"Cash Out"} />
      }
      open={open}
      placement="right"
      onClose={onClose}
      width={470}
    >
      {typeof amount === "number" ? (
        <CashInOutPanel
          closeOfDay={closeOfDay}
          cashDrawerBalance={cashDrawer?.get("balance")}
          nextDayDeposit={nextDayDeposit}
          isCashIn={isCashIn}
          amount={amount}
          onSucceed={onClose}
          onCancel={() => {
            setAmount(null);
            if (closeOfDay) {
              setCloseOfDay(false);
            }
          }}
        />
      ) : (
        <Space direction="vertical" size={"large"} className="full-width">
          <NumericUpdatePanel
            isCurrency
            onDone={(amount) => {
              if (typeof amount !== "number")
                return message.info(
                  isCashIn
                    ? intl.formatMessage({
                        defaultMessage: "Please enter cash in amount",
                      })
                    : intl.formatMessage({
                        defaultMessage: "Please enter cash out amount",
                      })
                );
              if (amount === 0)
                return message.info(
                  isCashIn
                    ? intl.formatMessage({
                        defaultMessage: "Cash in amount can not be 0",
                      })
                    : intl.formatMessage({
                        defaultMessage: "Cash out amount can not be 0",
                      })
                );

              if (!isCashIn) {
                if (amount > (cashDrawer?.get("balance") || 0))
                  return message.info(
                    intl.formatMessage(
                      {
                        defaultMessage: "Cash out amount can not larger than cash drawer balance of {amount}",
                      },
                      { amount: cashDrawer?.get("balance") || 0 }
                    )
                  );
              }

              if (!isCashIn && amount > cashDrawer.get("balance")) {
                return message.info(
                  intl.formatMessage(
                    {
                      defaultMessage: "Cash out amount can not exceed current cash balance of {amount}",
                    },
                    {
                      amount: <AmountDisplay value={cashDrawer.get("balance")} />,
                    }
                  )
                );
              }

              setAmount(amount);
              setCloseOfDay(false);
            }}
            buttonText={<FormattedMessage defaultMessage={"Preview"} />}
            buttonType="default"
          />
          {!isCashIn && typeof nextDayDeposit === "number" && (
            <div>
              <br />
              <br />
              <br />
              <Button
                block
                size="large"
                type="primary"
                ghost
                onClick={() => {
                  setAmount(formatPrice((cashDrawer?.get("balance") || 0) - nextDayDeposit));
                  setCloseOfDay(true);
                }}
              >
                <FormattedMessage defaultMessage={"Daily cash out"} />
              </Button>
            </div>
          )}
        </Space>
      )}
    </Drawer>
  );
};
