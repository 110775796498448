import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useCheckoutEditOrder, useSetCheckoutEditItem } from "@easybiz/context/src/CheckoutContext";
import { translate } from "@easybiz/utils";
import { Avatar, Col, List, Row } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import OrderPhotoGallery from "../OrderPhotoGallery";
import Title from "../Title";

export default ({ items, title, onAdd }) => {
  const editingOrder = useCheckoutEditOrder();
  const setEditItem = useSetCheckoutEditItem();

  return (
    <List
      style={{
        width: 400,
        maxHeight: window.innerHeight - 200,
        overflowY: "auto",
      }}
      dataSource={items}
      renderItem={(item) => {
        return (
          <List.Item
            className="selectable"
            onClick={() => {
              setEditItem(item);
            }}
          >
            <List.Item.Meta
              avatar={<Avatar icon={<EditOutlined />} />}
              title={
                <Title>
                  <FormattedMessage
                    defaultMessage={"Edit {productName}"}
                    values={{
                      productName: (
                        <span>
                          {item.product} <b>#{item.itemIndex + 1}</b>
                        </span>
                      ),
                    }}
                  />
                </Title>
              }
              description={
                <Row>
                  {Array.isArray(item.selectSteps) && item.selectSteps.length > 0 && (
                    <Col span={24}>
                      {item.selectSteps
                        .map(
                          ({ title, value }) =>
                            `${translate(title)}: ${
                              Array.isArray(value) ? value.map(({ code }) => code).join(", ") : value
                            }`
                        )
                        .join(" | ")}
                    </Col>
                  )}
                  {editingOrder && (
                    <Col span={24}>
                      <OrderPhotoGallery order={editingOrder} itemIndex={item.itemIndex + 1} limit={5} />
                    </Col>
                  )}
                </Row>
              }
            />
          </List.Item>
        );
      }}
      header={
        <List.Item className="selectable" onClick={onAdd}>
          <List.Item.Meta
            avatar={<Avatar icon={<PlusOutlined />} />}
            title={
              <Title>
                <FormattedMessage defaultMessage={"Add new {productName}"} values={{ productName: title }} />
              </Title>
            }
          />
        </List.Item>
      }
    />
  );
};
