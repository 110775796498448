import { EyeOutlined, ScanOutlined, ShareAltOutlined } from "@ant-design/icons";
import { usePaymentNextActionQR } from "@easybiz/component";
import { useRealmId, useToast } from "@easybiz/context/src/ConfigurationContext";
import { doc, getFirestore, onSnapshot } from "@firebase/firestore";
import { Alert, Button, Card, QRCode, Typography } from "antd";
import React, { useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export default function ({ paymentIntent, onSucceed, enableShare }) {
  const realmId = useRealmId();
  const intl = useIntl();
  const toast = useToast();
  const { id, next_action, description, payment_method_types } = paymentIntent || {};
  const qrCode = usePaymentNextActionQR(next_action, payment_method_types);
  const message = useToast();

  useEffect(() => {
    if (id) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}/payment_intents/${id}`), (paymentDoc) => {
        if (paymentDoc?.get("response")) {
          toast.success(intl.formatMessage({ defaultMessage: "Payment succeed" }));
          onSucceed(paymentDoc?.get("response"));
        }
      });
    }
  }, [realmId, id]);

  const onShare = useCallback(() => {
    if (navigator.share) {
      try {
        if (qrCode?.imageUrl) {
          navigator.share({
            url: qrCode?.imageUrl,
          });
        } else if (qrCode?.content) {
          navigator.share({
            textContent: qrCode?.content,
          });
        }
      } catch (err) {
        message.error(err.message);
      }
    }
  }, [qrCode]);

  return (
    <>
      <Alert
        type="info"
        icon={<ScanOutlined />}
        showIcon={true}
        message={<FormattedMessage tagName="b" defaultMessage="Ask customer scan QR code to complete the payment" />}
        description={qrCode?.title}
      />
      <div className="flex flex-row flex-center padding">
        <Card
          title={description}
          actions={
            enableShare
              ? [
                  <Button icon={<ShareAltOutlined />} type="text" onClick={onShare}>
                    <FormattedMessage defaultMessage={"Save / Share"} />
                  </Button>,
                ]
              : null
          }
        >
          {qrCode?.content ? (
            <QRCode size={256} value={qrCode?.content} icon={qrCode?.logo} bordered={false} />
            ) : (
            <Typography.Paragraph>{intl.formatMessage({ defaultMessage: "QR code not found" })}</Typography.Paragraph>
          )}
        </Card>
      </div>
      <Typography.Paragraph type="secondary" style={{ textAlign: "center" }}>
        <EyeOutlined />
        {` `}
        <FormattedMessage defaultMessage={"Real time payment status monitoring"} />
      </Typography.Paragraph>
    </>
  );
}
