import {
  BorderOutlined,
  CarOutlined,
  CheckOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CreditCardOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import { AmountDisplay, useOrderListItem } from "@easybiz/component";
import { useSetOpenOrderId } from "@easybiz/context/src/LiveStateContext";
import { usePrimaryColor, useSecondaryContainerColor } from "@easybiz/context/src/ThemingContext";
import { escapeRegExp, translate } from "@easybiz/utils";
import { Button, List, Progress, Tag, Typography } from "antd";
import React from "react";
import OrderPhotoGallery from "../OrderPhotoGallery";
import ServiceAvatar from "../ServiceAvatar";
import Title from "../Title";

export default function ({ order, search, showPhoto, selects, selectId, onSelect, mutipleSelect, fullOrderNo }) {
  const primaryColor = usePrimaryColor();
  const openOrder = useSetOpenOrderId();
  const secondaryContainer = useSecondaryContainerColor();
  const {
    title,
    description,
    progress,
    salesRemark,
    avatarId,
    statusChip,
    priceAmount,
    priceColor,
    completed,
    homeDelivery,
    deliveryDate,
    voidedDescription,
  } = useOrderListItem(order, !fullOrderNo, true);
  const selected = selectId ? selectId === order.id : Array.isArray(selects) && selects.indexOf(order.id) >= 0;
  const searchItems =
    search &&
    (order?.get("items") || []).filter(({ product }) => new RegExp(escapeRegExp(search), "i").test(translate(product)));

  let backgroundColor;
  if (selected) {
    backgroundColor = secondaryContainer;
  } else if (voidedDescription) {
    backgroundColor = "#EEEEEE";
  } else if (completed) {
    backgroundColor = secondaryContainer;
  }

  return (
    <List.Item
      className="selectable"
      style={{ backgroundColor: backgroundColor }}
      onClick={() => {
        if (onSelect && !mutipleSelect) {
          onSelect(order);
        } else {
          openOrder(order.id);
        }
      }}
      actions={[
        <div className="flex flex-align-end">
          {progress && (
            <Progress
              showInfo={false}
              percent={progress.percent * 100}
              strokeColor={progress.color}
              style={{ width: 80 }}
            />
          )}
          <Title color={priceColor}>
            <AmountDisplay value={priceAmount} />
          </Title>
          {statusChip && (
            <Tag
              color={statusChip.isPending ? "processing" : statusChip.isPaid ? "success" : null}
              icon={
                statusChip.isPending ? (
                  <ClockCircleOutlined />
                ) : statusChip.isPaid ? (
                  <CreditCardOutlined />
                ) : statusChip.isVoid ? (
                  <CloseOutlined />
                ) : null
              }
            >
              {statusChip.title}
            </Tag>
          )}
        </div>,
        ...(mutipleSelect
          ? [
              <Button
                type="text"
                icon={selected ? <CheckSquareOutlined style={{ color: primaryColor }} /> : <BorderOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  onSelect(order);
                }}
              />,
            ]
          : []),
      ]}
    >
      <List.Item.Meta
        avatar={avatarId && <ServiceAvatar serviceId={avatarId} />}
        title={
          <span>
            <b>{title}</b>
            {homeDelivery ? (
              <Tag icon={<CarOutlined />} color={"processing"}>
                {homeDelivery}
              </Tag>
            ) : null}
          </span>
        }
        description={
          <>
            {showPhoto !== false && <OrderPhotoGallery order={order} limit={5} />}
            <div>
              <FieldTimeOutlined /> {description}
            </div>
            {deliveryDate && !voidedDescription && (
              <div>
                <CheckOutlined /> {deliveryDate}
              </div>
            )}
            {voidedDescription && (
              <Typography.Text type="danger">
                <CloseOutlined />
                {voidedDescription}
              </Typography.Text>
            )}
            {Array.isArray(searchItems) && searchItems?.length > 0 && (
              <Typography.Text strong>
                {searchItems.map(({ product, qty }) => `${translate(product)} x ${qty}`)}
              </Typography.Text>
            )}
            {salesRemark && (
              <Typography.Text type="danger" strong>
                ** {salesRemark}
              </Typography.Text>
            )}
          </>
        }
      />
    </List.Item>
  );
}
