import { useImageHost } from "@easybiz/context/src/ConfigurationContext";
import { useBusinesses } from "@easybiz/context/src/RealmContext";
import { Avatar } from "antd";
import React from "react";

export default function ({ serviceId, serviceName }) {
  const imageHost = useImageHost();
  const services = useBusinesses();
  const found = services?.find((service) => service.id === serviceId);

  return (
    <Avatar
      shape="square"
      src={found?.photoId ? `${imageHost}/${found?.photoId}` : null}
    >
      {`${found?.title || serviceName || ""}`.slice(0, 2)}
    </Avatar>
  );
}
