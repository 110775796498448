"use client";

import { useBusinessAgentId } from "@easybiz/context/src/AccountAccessContext";
import useProcedureTitle from "../useProcedureTitle";

function ProcedureDisplay({ procedure, processing }) {
  const agentId = useBusinessAgentId();
  const title = useProcedureTitle(procedure, agentId, processing);

  return title || null;
}

export default ProcedureDisplay;
