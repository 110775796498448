"use client";

import { useCountryCode } from "@easybiz/context/src/ConfigurationContext";
import usePlacesAutocomplete from "use-places-autocomplete";

function useGooglePlaces(defaultValue) {
  const countryCode = useCountryCode();

  const result = usePlacesAutocomplete({
    defaultValue,
    initOnMount: true,
    requestOptions: {
      locationBias: "IP_BIAS",
      ...(countryCode && {
        componentRestrictions: { country: countryCode },
      }),
    },
    debounce: 300,
  });
  const {
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = result;

  return {
    value,
    setValue,
    clear: clearSuggestions,
    places: data,
  };
}

export default useGooglePlaces;
