import { CopyOutlined } from "@ant-design/icons";
import { doc, getDoc, getFirestore } from "@firebase/firestore";
import { Button, message } from "antd";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { getBusinessDomainName } from "@easybiz/utils";

export default ({ order }) => {
  const realmId = useRealmId();
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  const onCopy = () => {
    setLoading(true);
    getDoc(
      doc(
        getFirestore(),
        `realms/${realmId}/businesses/${order.get("business.id")}`
      )
    )
      .then((business) => {
        const domain = getBusinessDomainName(business);

        if (domain) {
          navigator.clipboard
            .writeText(`https://${domain}/payments?orderId=${order.id}`)
            .then(() =>
              message.success(
                intl.formatMessage({
                  defaultMessage: "Payment link copied to your clipboard",
                })
              )
            )
            .catch((err) => message.error(err.message));
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Button loading={loading} icon={<CopyOutlined />} block onClick={onCopy}>
      <FormattedMessage tagName="span" defaultMessage="Payment link" />
    </Button>
  );
};
