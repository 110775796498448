import { useUpdateCheckoutItems } from "@easybiz/checkout";
import { AmountDisplay } from "@easybiz/component";
import { useSetCheckoutEditItem } from "@easybiz/context/src/CheckoutContext";
import { useToast } from "@easybiz/context/src/ConfigurationContext";
import { formatPrice, ITEM_STEP_SERVICE_TYPES, STEP_TYPE_SINGLE_SELECT, translate } from "@easybiz/utils";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import useLineItemStepMenus from "../useLineItemStepMenus";

function useBagAlterProductSelectPanel(item, initStepId) {
  const [activeKey, setActiveKey] = useState(initStepId);
  const [itemTotalVisible, setItemTotalVisible] = useState(false);
  const steps = useLineItemStepMenus(item);
  const setEditItem = useSetCheckoutEditItem();
  const updateItems = useUpdateCheckoutItems();
  const { product, itemIndex, missingStepId, accessories, selectSteps, subOptions, unitPrice, date } = item || {};
  const toast = useToast();
  const intl = useIntl();
  const isEditing = Number.isInteger(itemIndex);

  useEffect(() => {
    if (!activeKey || !steps.find((step) => step.key === activeKey)) {
      setActiveKey(steps[0].key);
    }
  }, [activeKey, steps]);

  useEffect(() => {
    if (missingStepId) {
      const initStep = steps.find((step) => step.key === missingStepId);

      if (initStep) {
        setActiveKey(initStep.key);
        toast.info(
          intl.formatMessage(
            { defaultMessage: "Please enter {option} of item {itemName}" },
            {
              option: initStep.title || initStep.label,
              itemName: `${product}${Number.isInteger(itemIndex) ? ` #${itemIndex + 1}` : ""}`,
            }
          )
        );
      }

      const { missingStepId: discard, ...others } = item;
      // Reset
      setEditItem(others);
    }
  }, [missingStepId]);

  const accessoryCount = Array.isArray(accessories)
    ? [0, ...accessories.map(({ qty }) => qty || 0)].reduce((a, b) => a + b)
    : 0;
  const noPrice =
    Array.isArray(subOptions) &&
    subOptions.length > 0 &&
    !subOptions.find(({ price }) => typeof price === "number" && price > 0);
  const itemTotal = noPrice
    ? typeof unitPrice === "number"
      ? unitPrice
      : 0
    : formatPrice(
        [0, ...(subOptions || []).map(({ price, discountAmount }) => (price || 0) - (discountAmount || 0))].reduce(
          (a, b) => a + b
        )
      );
  const activeStepIndex = steps?.findIndex((step) => step.key === activeKey);
  const activeStep = steps[activeStepIndex];
  const nextStep = steps.find((step, index) => step.detail && index > activeStepIndex);
  const requireSetTotal = noPrice && activeStep?.key === ITEM_STEP_SERVICE_TYPES;
  const selectStep =
    activeStep && Array.isArray(selectSteps) && selectSteps.find((added) => added.id === activeStep.key);

  const onStepChange = (newUpdates) => {
    const {
      productId,
      product,
      photoId,
      unit,
      selectSteps,
      subOptions,
      note,
      freeNote,
      commons,
      accessories,
      date,
      manualItemTotal,
      labelIndexes,
    } = { ...item, ...newUpdates };

    const subServiceTotal = Array.isArray(subOptions)
      ? formatPrice(
          [0, ...(subOptions || []).map(({ price, discountAmount }) => (price || 0) - (discountAmount || 0))].reduce(
            (a, b) => a + b
          )
        )
      : 0;

    const newSelect = {
      productId,
      product,
      qty: 1,
      optionId: item.optionId || `${Date.now()}`, // New option for each item
      selectSteps: selectSteps || [],
      subOptions: subOptions || [],
      unitPrice: subServiceTotal > 0 ? subServiceTotal : typeof manualItemTotal === "number" ? manualItemTotal : 0,
      ...(typeof manualItemTotal === "number" && subServiceTotal === 0 && { manualItemTotal }),
      ...(photoId && { photoId }),
      ...(unit && { unit }),
      ...(date && { date }),
      ...(note && { note }),
      ...(freeNote && { freeNote }),
      ...(commons && { commons }),
      ...(Array.isArray(accessories) && { accessories }),
      ...(isEditing && { itemIndex }),
      ...(labelIndexes && { labelIndexes }), // Preserve label indexes if have
    };

    const newIndex = updateItems(newSelect);

    if (!item.optionId && Number.isInteger(newIndex)) {
      toast.success(
        intl.formatMessage({ defaultMessage: "Item {index} added to order" }, { index: `#${newIndex + 1}` })
      );
      setEditItem({ ...newSelect, itemIndex: newIndex });
    }

    if (
      nextStep &&
      activeStep?.type === STEP_TYPE_SINGLE_SELECT &&
      selectSteps?.find((step) => step.id === activeStep.key)
    ) {
      // Auto next step
      setActiveKey(nextStep.key);
    }
  };

  const onStepValueChange = (value) => {
    const selectIndex = Array.isArray(selectSteps) ? selectSteps.findIndex((added) => added.id === activeStep.key) : -1;

    if (selectIndex >= 0) {
      if (value) {
        onStepChange({
          selectSteps: [
            ...selectSteps.slice(0, selectIndex),
            { ...selectSteps[selectIndex], value },
            ...selectSteps.slice(selectIndex + 1),
          ],
        });
      } else {
        onStepChange({
          selectSteps: [...selectSteps.slice(0, selectIndex), ...selectSteps.slice(selectIndex + 1)],
        });
      }
    } else {
      onStepChange({
        selectSteps: [
          ...(selectSteps || []),
          {
            id: activeStep.key,
            title: activeStep.label,
            value,
            ...(activeStep.code && { code: activeStep.code }),
            ...(activeStep.printOnLabel && { printOnLabel: activeStep.printOnLabel }),
          },
        ],
      });
    }
  };

  const onCopy = () => {
    const newCopy = {
      ...item,
      itemIndex: null,
      optionId: `${Date.now()}`,
    };
    const newIndex = updateItems(newCopy);

    if (Number.isInteger(newIndex)) {
      toast.success(
        intl.formatMessage({ defaultMessage: "New item {index} copied to order" }, { index: `#${newIndex + 1}` })
      );
      setEditItem({ ...newCopy, itemIndex: newIndex });
    }
  };

  const onNext = () => {
    if (requireSetTotal) {
      return setItemTotalVisible(true);
    }

    if (nextStep) {
      setActiveKey(nextStep.key);
    } else {
      if (!Number.isInteger(itemIndex)) {
        return toast.info(
          intl.formatMessage({
            defaultMessage: `You haven't enter any information about the new item.`,
          })
        );
      }

      setEditItem(null);
    }
  };

  const productTitle = isEditing ? `${translate(product)} #${itemIndex + 1}` : null;

  const onSetPrice = (manualItemTotal) => {
    onStepChange({ manualItemTotal });
    setItemTotalVisible(false);
    setActiveKey(nextStep?.key);
  };

  return {
    title: isEditing ? (
      productTitle
    ) : (
      <FormattedMessage defaultMessage="Add New {productName}" values={{ productName: product }} />
    ),
    amountDisplay: (
      <FormattedMessage
        defaultMessage={"Item total: {amount}"}
        values={{
          amount: <AmountDisplay value={itemTotal} />,
        }}
      />
    ),
    copyTitle: isEditing && (
      <FormattedMessage
        defaultMessage={"Copy {lineItem} details and add another line item?"}
        values={{
          lineItem: productTitle,
        }}
      />
    ),
    nextStepTitle: nextStep ? (
      requireSetTotal ? (
        <FormattedMessage defaultMessage={"Set price & next"} />
      ) : (
        <FormattedMessage defaultMessage={"Next"} />
      )
    ) : date ? (
      <FormattedMessage defaultMessage={"Complete & Close"} />
    ) : (
      <FormattedMessage defaultMessage={"Skip & Close"} />
    ),
    isEditing,
    steps,
    activeKey,
    activeStep,
    stepValue: selectStep?.value,
    nextStep,
    nextHeighLight: requireSetTotal && typeof price !== "number",
    itemTotalVisible,
    accessoryCount,
    onGoToStep: setActiveKey,
    onStepChange,
    onStepValueChange,
    onCopy,
    onNext,
    onSetPrice,
    onCancelPrice: () => setItemTotalVisible(false),
    setItemTotalVisible,
  };
}

export default useBagAlterProductSelectPanel;
