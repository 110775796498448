export const BUSINESS_TYPE_SHOP = "shop";
export const BUSINESS_TYPE_ONLINE = "online";
export const BUSINESS_TYPE_FACTORY = "factory";

export const BUSINESS_CODE_MARKETING = "marketing";

export const BUSINESS_SECTOR_LAUNDRY = "LAUNDRY";
export const BUSINESS_SECTOR_ALTERATION = "ALTERATION";
export const BUSINESS_SECTOR_BAG_SERVICE = "BAG SERVICE";
export const BUSINESS_SECTOR_AIRCON_SERVICE = "AIRCON SERVICE";
export const BUSINESS_SECTOR_CLEANING = "HOME CLEANING";
export const BUSINESS_SECTOR_HAIR_SALON = "HAIR SALON";
export const BUSINESS_SECTOR_MASSAGE = "MASSAGE";
export const BUSINESS_SECTOR_BEAUTY_SHOP = "BEAUTY SHOP";
export const BUSINESS_SECTOR_YOGA_FITNESS = "YOGA FITNESS";
export const BUSINESS_SECTOR_PEST_CONTROL = "PEST CONTROL";
export const BUSINESS_SECTOR_HANDYMAN = "HANDYMAN";

export const BUSINESS_LIMIT_ALL = 'ALL';

export const BUSINESS_SECTORS = [
  BUSINESS_SECTOR_LAUNDRY,
  BUSINESS_SECTOR_ALTERATION,
  BUSINESS_SECTOR_BAG_SERVICE,
  BUSINESS_SECTOR_AIRCON_SERVICE,
  BUSINESS_SECTOR_CLEANING,
  BUSINESS_SECTOR_PEST_CONTROL,
  BUSINESS_SECTOR_HANDYMAN,
  BUSINESS_SECTOR_HAIR_SALON,
  BUSINESS_SECTOR_MASSAGE,
  BUSINESS_SECTOR_BEAUTY_SHOP,
  BUSINESS_SECTOR_YOGA_FITNESS
];

export const TEMPLATE_ID_LAUNDRY = "laundry";
export const TEMPLATE_ID_UPHOLSTERY_CLEANING = "upholstery_cleaning";
export const TEMPLATE_ID_ALTERATION = "alteration";
export const TEMPLATE_ID_BAG_SERVICE = "bag_service";
export const TEMPLATE_ID_AIRCON_SERVICE = "aircon_service";
export const TEMPLATE_ID_HOME_CLEANING = "home_cleaning";
export const TEMPLATE_ID_PEST_CONTROL = "pest_control";
export const TEMPLATE_ID_HANDYMAN = "handyman";
export const TEMPLATE_ID_HAIR_SALON = "hair_salon";
export const TEMPLATE_ID_MASSAGE = "massage";
export const TEMPLATE_ID_BEAUTY_SHOP = "beauty_shop";
export const TEMPLATE_ID_YOGA_FITNESS = "yoga_fitness";