import { OrderNoDisplay } from "@easybiz/component";
import { useOrderDoc } from "@easybiz/web-firebase";
import { Button, Collapse, Drawer } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import LineItemStatusList from "../LineItemStatusList.js/index.js";
import Title from "../Title";
import { useSetOpenOrderId } from "@easybiz/context/src/LiveStateContext.js";

export default ({ viewOrder, orderDoc, open, onClose }) => {
  const setOpenOrderId = useSetOpenOrderId();
  const { id, orderNo, businessCode, name, index, items } = viewOrder || {};
  const order = useOrderDoc(id) || orderDoc;

  return (
    <Drawer
      title={
        <span>
          <FormattedMessage
            defaultMessage={"Order {orderNo}"}
            values={{
              orderNo: <OrderNoDisplay orderNo={orderNo} businessCode={businessCode} />,
            }}
          />
          {Number.isInteger(index) ? (
            <span>
              {" "}
              (
              <FormattedMessage
                defaultMessage={"Line item {index}"}
                values={{
                  index: `#${index + 1} ${name}`,
                }}
              />
              )
            </span>
          ) : null}
        </span>
      }
      open={open}
      onClose={onClose}
      placement="right"
      width={470}
      extra={
        orderDoc ? null : (
          <Button
            onClick={() => {
              setOpenOrderId(id);
            }}
          >
            <FormattedMessage defaultMessage={"Order detail"} />
          </Button>
        )
      }
    >
      {order && Array.isArray(items) && items.length > 1 ? (
        <Collapse>
          {items.map(({ p, o, i, c, w }) => {
            const total = (c || 0) + (w || 0);

            return (
              <Collapse.Panel
                key={`${i}`}
                header={
                  <span>
                    <FormattedMessage
                      tagName={"b"}
                      defaultMessage={"Line item {index}"}
                      values={{ index: `#${i + 1}` }}
                    />{" "}
                    {`${p} ${o}`}
                  </span>
                }
                extra={<Title type={w ? "warning" : "success"}>{`${c || 0}/${total}`}</Title>}
              >
                <LineItemStatusList order={order} itemIndex={i} />
              </Collapse.Panel>
            );
          })}
        </Collapse>
      ) : (
        <LineItemStatusList order={order} itemIndex={index ?? 0} />
      )}
    </Drawer>
  );
};
