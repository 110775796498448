import { NameDisplay } from "@easybiz/component";
import { useBusinessCode } from "@easybiz/context/src/ConfigurationContext";
import { usePOSSettings } from "@easybiz/context/src/POSContext";
import { FormattedMessage } from "react-intl";

function useGeoLocationBlocker(permission, Bold) {
  const businessCode = useBusinessCode();
  const { stationLocation } = usePOSSettings();
  const storeLocationReady = typeof stationLocation?.lat === "number" && typeof stationLocation?.lng === "number";
  const permissionError = typeof permission === "object";

  return {
    title: storeLocationReady ? (
      permission === false ? (
        <FormattedMessage defaultMessage={"Multi-station require your browser support Geolocation"} />
      ) : (
        <FormattedMessage defaultMessage={"Multi-station require Geolocation permission"} />
      )
    ) : (
      <FormattedMessage defaultMessage={"Multi-station require your set your store address"} />
    ),
    open: permission !== true || !storeLocationReady,
    permissionError,
    alert: !storeLocationReady
      ? {
          title: <FormattedMessage defaultMessage={"Your store address is missing"} />,
          description: (
            <FormattedMessage
              defaultMessage={
                "Go to your web console (Store => {storeName} => Store profile) to set your store address"
              }
              values={{
                storeName: <NameDisplay nameKey={businessCode} />,
              }}
            />
          ),
        }
      : permission === false
      ? {
          title: <FormattedMessage defaultMessage={"Your browser does not support Geolocation"} />,
          description: (
            <FormattedMessage
              defaultMessage={
                "Your store <b>{storeName}</b> has enabled <b>Multi-station function</b>, to keep your web POS sign in under Multi-station, you need to use browser that support web <b>Geolocation</b> technology."
              }
              values={{
                b: (t) => <Bold>{t}</Bold>,
                storeName: <NameDisplay nameKey={businessCode} />,
              }}
            />
          ),
          browsers: [
            {
              href: "https://www.google.com/chrome/",
              icon: "https://www.google.com/chrome/static/images/chrome-logo-m100.svg",
              title: "Google Chrome",
            },
            {
              href: "https://www.microsoft.com/edge/",
              icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/Microsoft_Edge_logo_%282019%29.svg/2048px-Microsoft_Edge_logo_%282019%29.svg.png",
              title: "Microsoft Edge",
            },
          ],
        }
      : {
          title: permission?.message,
          description: (
            <FormattedMessage
              defaultMessage={
                "Please check your address bar or site menu ensure Location permission is granted and then reload the page."
              }
            />
          ),
          guideImg: "/images/guide-location.png",
        },
  };
}

export default useGeoLocationBlocker;
