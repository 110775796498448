"use client";

import { createContext, useContext, useMemo, useState } from "react";

const DeviceRegistrationContext = createContext();

export function DeviceRegistrationProvider({ deviceDoc, children }) {
  const [location, setLocation] = useState();

  const context = useMemo(() => {
    return {
      location,
      setLocation,
      deviceDoc
    };
  }, [deviceDoc, location]);

  return <DeviceRegistrationContext.Provider value={context}>{children}</DeviceRegistrationContext.Provider>;
}

export const useDeviceLocation = () => useContext(DeviceRegistrationContext).location;
export const useDeviceLocationSetter = () => useContext(DeviceRegistrationContext).setLocation;
export const useRegisteredDevice = () => useContext(DeviceRegistrationContext).deviceDoc;
export const useIsWebcameraEnabled = () => useContext(DeviceRegistrationContext).deviceDoc?.get("webCamera");
