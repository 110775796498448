import { useClientType } from "@easybiz/context/src/ConfigurationContext";
import { CLIENT_POS } from "@easybiz/utils";
import { Popconfirm } from "antd";
import Title from "../Title";

export default function ({ title, danger, loading, onConfirm, okButtonProps, okText, children, open, onOpenChange }) {
  const client = useClientType();

  return (
    <Popconfirm
      open={open}
      title={<Title type={danger ? "danger" : null}>{title}</Title>}
      loading={loading}
      onConfirm={onConfirm}
      onOpenChange={onOpenChange}
      okText={okText}
      {...(client === CLIENT_POS && {
        okButtonProps: { ...okButtonProps, size: "large", style: { minWidth: 81 } },
        cancelButtonProps: { size: "large" },
      })}
    >
      {children}
    </Popconfirm>
  );
}
