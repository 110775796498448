import {
  AccountBookOutlined,
  CarOutlined,
  CheckOutlined,
  DesktopOutlined,
  DollarOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { useAuthUser } from "@easybiz/context/src/AuthenticationContext";
import { CashDrawerProvider } from "@easybiz/context/src/CashDrawerContext";
import { useBusinessCode, useInstallationId, useToast } from "@easybiz/context/src/ConfigurationContext";
import {
  useClientDate,
  useOpenOrderId,
  useSetDisplayMode,
  useSetOpenOrderId,
} from "@easybiz/context/src/LiveStateContext";
import { useIsRealmReady, useSuppliers } from "@easybiz/context/src/RealmContext";
import { usePOSSettings } from "@easybiz/context/src/POSContext";
import { useSurfaceColor } from "@easybiz/context/src/ThemingContext";
import {
  BUSINESS_TYPE_SHOP,
  DISPLAY_MODE_CHECK_IN,
  DISPLAY_MODE_CHECK_OUT,
  DISPLAY_MODE_COLLECTION,
  DISPLAY_MODE_CONFIRM_ORDER,
  DISPLAY_MODE_PRODUCTION,
  DISPLAY_MODE_SALES,
} from "@easybiz/utils";
import {
  ApplicationLauncher,
  LocationMonitor,
  OfflineAlert,
  OrderDrawer,
  SystemNoticeBanner,
  WebCheckout,
} from "@easybiz/web-admin";
import { useCashDrawerDoc } from "@easybiz/web-firebase";
import { getAuth, signInWithCustomToken } from "@firebase/auth";
import { deleteDoc, doc, getFirestore, onSnapshot } from "@firebase/firestore";
import { Space } from "antd";
import query from "query-string";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import CashDrawerButton from "./components/CashDrawerButton";
import CollectionOptionsLoader from "./components/CollectionOptionsLoader";
import POSDisplayModeButton from "./components/POSDisplayModeButton";
import POSHomeLeftPanel from "./components/POSHomeLeftPanel";
import POSHomeRightPanel from "./components/POSHomeRightPanel";
import POSSettingConvert from "./components/POSSettingConvert";
import POSSignInPanel from "./components/POSSignInPanel";

export default function POSWebApplication() {
  const businessCode = useBusinessCode();
  const cashDrawer = useCashDrawerDoc(businessCode);
  const user = useAuthUser();
  const { multiStation } = usePOSSettings();
  const realmReady = useIsRealmReady();
  const params = query.parse(window?.location.search);
  const secondaryContainer = useSurfaceColor();
  const installId = useInstallationId();
  const openOrderId = useOpenOrderId();
  const setOpenOrderId = useSetOpenOrderId();
  const setDisplayMode = useSetDisplayMode();
  const suppliers = useSuppliers();
  const date = useClientDate();
  const toast = useToast();
  const intl = useIntl();

  useEffect(() => {
    if (params.token) {
      signInWithCustomToken(getAuth(), params.token)
        .then(() => {
          // Sign in success
          window.location.replace("/");
        })
        .catch(() => {
          toast.error(intl.formatMessage({ defaultMessage: "Token expired" }));
        });
    }
  }, [params.token]);

  useEffect(() => {
    if (installId) {
      return onSnapshot(doc(getFirestore(), `devices/${installId}`), (device) => {
        if (device.get("token")) {
          signInWithCustomToken(getAuth(), device.get("token")).catch(() => {
            toast.error(intl.formatMessage({ defaultMessage: "Token expired" }));
            deleteDoc(device.ref);
          });
        }
      });
    }
  }, [installId]);

  useEffect(() => {
    if (!user) {
      setDisplayMode(null);
    }
  }, [Boolean(user)]);

  return (
    <CashDrawerProvider cashDrawer={cashDrawer}>
      {user === false ? (
        <POSSignInPanel />
      ) : Array.isArray(user?.permissions) && realmReady ? (
        <WebCheckout newOrderType={BUSINESS_TYPE_SHOP} businessCode={businessCode} date={date}>
          <div className="flex full-screen">
            <OfflineAlert />
            <SystemNoticeBanner />
            {multiStation && <LocationMonitor />}
            <div className="flex flex-fill flex-row scroll-container">
              <div className="flex flex-fill">
                <POSHomeLeftPanel />
                <div className="flex flex-row flex-space-between flex-align-center padding-half">
                  <Space>
                    <POSDisplayModeButton targetMode={DISPLAY_MODE_CONFIRM_ORDER} icon={<AccountBookOutlined />} />
                    <POSDisplayModeButton targetMode={DISPLAY_MODE_CHECK_OUT} icon={<ShopOutlined />} />
                    <POSDisplayModeButton targetMode={DISPLAY_MODE_CHECK_IN} icon={<CarOutlined />} />
                    <POSDisplayModeButton targetMode={DISPLAY_MODE_COLLECTION} icon={<CheckOutlined />} />
                    {suppliers && (
                      <POSDisplayModeButton targetMode={DISPLAY_MODE_PRODUCTION} icon={<DesktopOutlined />} />
                    )}
                  </Space>
                  <Space>
                    <POSDisplayModeButton targetMode={DISPLAY_MODE_SALES} icon={<DollarOutlined />} />
                    <CashDrawerButton />
                  </Space>
                </div>
              </div>
              <div
                className="flex"
                style={{ width: 470, minWidth: 470, padding: "8px 8px 0px 8px", background: secondaryContainer }}
              >
                <POSHomeRightPanel />
              </div>
              <OrderDrawer
                cashDrawer={cashDrawer}
                placement={"right"}
                orderId={openOrderId}
                open={Boolean(openOrderId)}
                onClose={() => setOpenOrderId(null)}
              />
              <CollectionOptionsLoader />
            </div>
          </div>
          {/* TODO: REMOVE */}
          <POSSettingConvert />
        </WebCheckout>
      ) : (
        <ApplicationLauncher />
      )}
    </CashDrawerProvider>
  );
}
