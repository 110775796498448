import { CalendarOutlined, ShopOutlined } from "@ant-design/icons";
import { BUSINESS_SECTOR_LAUNDRY, CLIENT_POS } from "@easybiz/utils";
import { Badge, Button, Popover, Segmented } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import CollectionDatesCalendar from "../CollectionDatesCalendar";
import CollectionPlacesList from "../CollectionPlacesList";
import ScheduleConfirmDialog from "../ScheduleConfirmDialog";
import Title from "../Title";
import { useClientType } from "@easybiz/context/src/ConfigurationContext";
import { useToDisplayName } from "@easybiz/context/src/NamingContext";

const MODE_DATE = "date";
const MODE_PLACE = "place";

export default function CollectionScheduleButton({ schedule, children, disabled, order, collectionOptions }) {
  const client = useClientType();
  const [mode, setMode] = useState(null);
  const [pendingSchedule, setPendingSchedule] = useState();
  const [collectionPlace, setCollectionPlace] = useState();
  const toNameDisplay = useToDisplayName();
  const isLaundry = order?.get("service.sector") === BUSINESS_SECTOR_LAUNDRY;

  return (
    <>
      <Popover
        trigger={"click"}
        placement={"left"}
        title={<Title>{children}</Title>}
        content={
          <div style={{ width: 550, height: 550, overflowY: "auto" }}>
            {isLaundry && (
              <div className="flex flex-row flex-center padding-vertical">
                <Badge count={collectionPlace ? toNameDisplay(collectionPlace) || collectionPlace : null}>
                  <Segmented
                    value={mode}
                    size={client === CLIENT_POS ? "large" : undefined}
                    onChange={setMode}
                    options={[
                      {
                        icon: <CalendarOutlined />,
                        label: <FormattedMessage defaultMessage={"Collection date"} />,
                        value: MODE_DATE,
                        disabled: !collectionPlace,
                      },
                      {
                        icon: <ShopOutlined />,
                        label: <FormattedMessage defaultMessage={"Collection place"} />,
                        value: MODE_PLACE,
                      },
                    ]}
                  />
                </Badge>
              </div>
            )}
            {MODE_PLACE === mode ? (
              <CollectionPlacesList
                header={!isLaundry && <FormattedMessage defaultMessage={"Select collection place"} />}
                select={collectionPlace}
                onSelect={(place, e) => {
                  if (isLaundry) {
                    e.stopPropagation();
                    setCollectionPlace(place);
                    setMode(MODE_DATE);
                  } else {
                    setMode(null);
                    setPendingSchedule({ collectionPlace: place });
                  }
                }}
              />
            ) : (
              <CollectionDatesCalendar
                collectionOptions={collectionOptions}
                order={order}
                schedule={schedule}
                onSelect={(schedule) => {
                  setMode(null);
                  setPendingSchedule(collectionPlace ? { ...schedule, collectionPlace } : schedule);
                }}
              />
            )}
          </div>
        }
        open={Boolean(mode)}
        onOpenChange={(open) => {
          setCollectionPlace(open ? order?.get("collectionPlace") : null);
          setMode(open ? (order?.get("collectionPlace") && isLaundry ? MODE_DATE : MODE_PLACE) : null);
        }}
        destroyTooltipOnHide
      >
        <Button icon={<CalendarOutlined />} block disabled={disabled}>
          {children}
        </Button>
      </Popover>
      <ScheduleConfirmDialog
        order={order}
        newSchedule={pendingSchedule}
        open={Boolean(pendingSchedule)}
        onCancel={() => setPendingSchedule(null)}
      />
    </>
  );
}
