import { LazyImage } from "@easybiz/web-ui";
import { useOrderDoc } from "@easybiz/web-firebase";
import { Space } from "antd";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import LabelButton from "../LabelButton";
import LabelStatusTag from "../LabelStatusTag";
import { useOpenGallery } from "@easybiz/context/src/PhotoGalleryContext";
import { useImageHost } from "@easybiz/context/src/ConfigurationContext";

export default function ({
  extra,
  orderId,
  order,
  itemIndex,
  limit,
  showStatusTag,
  size,
  width,
  orderOnly,
  wrap,
  onSelectChange,
  selects,
  disabled,
}) {
  const orderDoc = useOrderDoc(!order && orderId);
  const setPhotos = useOpenGallery();
  const imageHost = useImageHost();

  const displayPhotos = useMemo(() => {
    return (order?.get("photos") || orderDoc?.get("photos") || [])
      .filter((photo) => (orderOnly ? !photo.l : typeof itemIndex !== "number" || itemIndex === photo.l))
      .map((photo) => {
        return {
          id: photo.i,
          url: `${imageHost}/${photo.i}`,
          title: `${dayjs.unix(photo.t).format("DD/MM/YYYY HH:mm")} | ${photo.o}`,
          timestamp: photo.t,
        };
      });
  }, [order, orderDoc, imageHost, orderOnly, itemIndex]);

  if (extra || displayPhotos.length > 0 || onSelectChange) {
    return (
      <Space direction="horizontal" align="center" style={{ width: width || "100%", overflowX: "auto" }} wrap={wrap}>
        {extra}
        {typeof itemIndex === "number" && onSelectChange && (
          <LabelButton sortIndex={itemIndex} selects={selects} onChange={onSelectChange} disabled={disabled} />
        )}
        {showStatusTag && <LabelStatusTag order={order} sortIndex={itemIndex} />}
        {displayPhotos.map((photo, index) => {
          if (typeof limit === "number" && index >= limit) return null;

          return (
            <div key={`${index}`} style={{ width: size || 70 }}>
              <div
                className="selectable"
                onClick={(e) => {
                  e.stopPropagation();
                  setPhotos({ photos: displayPhotos, viewIndex: index, orderId: orderId || order?.id, itemIndex });
                }}
              >
                <LazyImage src={photo.url} width={size || 70} height={size || 70} />
              </div>
            </div>
          );
        })}
      </Space>
    );
  } else {
    return null;
  }
}
