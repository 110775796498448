import { useLineItemsSteps } from "@easybiz/context/src/CheckoutContext";
import {
  ITEM_STEP_ACCESSORY,
  ITEM_STEP_COLLECTION_DATE,
  ITEM_STEP_NOTE,
  ITEM_STEP_SERVICE_TYPES,
  translate,
} from "@easybiz/utils";
import { useMemo } from "react";
import { useIntl } from "react-intl";

function useLineItemStepMenus(item) {
  const lineItemsSteps = useLineItemsSteps();
  const intl = useIntl();

  return useMemo(() => {
    const { selectSteps, subOptions, date } = item || {};

    return [
      ...[
        ...(lineItemsSteps || []).map(({ id, title, ...step }) => ({
          ...step,
          key: id,
          label: translate(title),
          danger: !selectSteps?.find((step) => step.id === id),
        })),
        {
          key: ITEM_STEP_SERVICE_TYPES,
          type: ITEM_STEP_SERVICE_TYPES,
          label: intl.formatMessage({ defaultMessage: "Service Types" }),
          danger: !Array.isArray(subOptions) || subOptions.length === 0,
        },
        {
          key: ITEM_STEP_COLLECTION_DATE,
          type: ITEM_STEP_COLLECTION_DATE,
          label: intl.formatMessage({ defaultMessage: "Collection" }),
          title: intl.formatMessage({
            defaultMessage: "Estimated Collection Date",
          }),
          danger: !date,
        },
      ].map((item) => ({
        ...item,
        detail: true,
      })),
      {
        key: ITEM_STEP_NOTE,
        type: ITEM_STEP_NOTE,
        label: intl.formatMessage({ defaultMessage: "Item Notes" }),
      },
      {
        key: ITEM_STEP_ACCESSORY,
        type: ITEM_STEP_ACCESSORY,
        label: intl.formatMessage({ defaultMessage: "Accessory" }),
      },
    ];
  }, [item, lineItemsSteps]);
}

export default useLineItemStepMenus;
