import { useActionCallback } from "@easybiz/hook";
import { ADMIN_ACTION_BOOT_INTERCOM, OPERATION_GROUP_ADMIN } from "@easybiz/utils";
import { useCallback } from "react";

function useAdminIntercomBoot(callback) {
  const onSubmit = useCallback(() => {
    return [OPERATION_GROUP_ADMIN, ADMIN_ACTION_BOOT_INTERCOM];
  }, []);

  return useActionCallback(onSubmit, callback);
}

export default useAdminIntercomBoot;
