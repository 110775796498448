import { LoginOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import ClockInDialog from "./ClockInDialog";
import ClockOutDialog from "./ClockOutDialog";
import { useCheckInStaff, useIsPlatformMode } from "@easybiz/context/src/AuthenticationContext";
import { usePOSSettings } from "@easybiz/context/src/RealmContext";
import { useRegisteredDevice } from "@easybiz/context/src/DeviceRegistrationContext";
import { useRealmId } from "@easybiz/context/src/ConfigurationContext";

export default () => {
  const checkInStaff = useCheckInStaff();
  const [checkInVisible, setCheckInVisible] = useState(false);
  const [checkOutVisible, setCheckOutVisible] = useState(false);
  const { enforceCheckIn } = usePOSSettings();
  const realmId = useRealmId();
  const device = useRegisteredDevice();
  const platformMode = useIsPlatformMode();

  useEffect(() => {
    if (checkInStaff && checkInVisible) {
      setCheckInVisible(false);
    }

    if (!checkInStaff && checkOutVisible) {
      setCheckOutVisible(false);
    }
  }, [checkInStaff]);

  return (
    <>
      <Button
        size="large"
        icon={<LoginOutlined />}
        type={checkInStaff ? "primary" : "default"}
        ghost={Boolean(checkInStaff)}
        danger={!checkInStaff}
        loading={Boolean(realmId && !device)}
        onClick={() => {
          if (checkInStaff) {
            setCheckOutVisible(true);
          } else {
            setCheckInVisible(true);
          }
        }}
        style={{ maxWidth: 180, overflow: "hidden" }}
      >
        {checkInStaff ? (
          <span>
            <b>{checkInStaff.name}</b>
          </span>
        ) : (
          <FormattedMessage tagName="span" defaultMessage="Clock in" />
        )}
      </Button>
      <ClockInDialog
        open={platformMode || checkInStaff || !device ? false : checkInVisible || Boolean(enforceCheckIn) || false}
        onCancel={() => setCheckInVisible(false)}
      />
      <ClockOutDialog
        open={!checkInStaff || !device ? false : checkOutVisible}
        onCancel={() => setCheckOutVisible(false)}
      />
    </>
  );
};
