"use client";

import { useCurrency, useLocale } from "@easybiz/context/src/ConfigurationContext";

function LocaleCurrency({ value, accounting }) {
  const lang = useLocale();
  const currency = useCurrency();

  if (typeof value === "number" || (typeof value === "string" && !isNaN(value))) {
    return currency
      ? parseFloat(value).toLocaleString(lang, {
          style: "currency",
          currency,
          currencyDisplay: "narrowSymbol",
          ...(accounting && {
            currencySign: "accounting",
          }),
        })
      : value;
  } else {
    return null;
  }
}

export default LocaleCurrency;
