"use client";

import { useAddReturnListener, useRemoveReturnListener } from "@easybiz/context/src/WebKeyboardListenerContext";
import { useEffect } from "react";

function ReturnListener({ disabled, callback }) {
  const addEventListener = useAddReturnListener();
  const removeEventListener = useRemoveReturnListener();

  useEffect(() => {
    if (!disabled) {
      addEventListener(callback);
      return () => removeEventListener(callback);
    }
  }, [disabled, callback]);

  return null;
}

export default ReturnListener;
