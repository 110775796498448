import { ShopFilled } from "@ant-design/icons";
import { NameDisplay } from "@easybiz/component";
import { useBusinessCode } from "@easybiz/context/src/ConfigurationContext";
import { useOTAUpdate } from "@easybiz/context/src/InstallationContext";
import { Badge, Button } from "antd";
import { useIntl } from "react-intl";

export default function ({ onClick }) {
  const businessCode = useBusinessCode();
  const haveUpdate = useOTAUpdate();
  const intl = useIntl();

  return (
    <Badge
      count={
        haveUpdate
          ? intl.formatMessage({
              defaultMessage: "New version",
            })
          : null
      }
    >
      <Button icon={<ShopFilled />} onClick={onClick}>
        <span>
          <NameDisplay nameKey={businessCode} />
        </span>
      </Button>
    </Badge>
  );
}
