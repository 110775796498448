import { AddressDisplay, DateDisplay, LogisticStatusDisplay, TaskTitle } from "@easybiz/component";
import { useClientType } from "@easybiz/context/src/ConfigurationContext";
import { CLIENT_CONSOLE, getJobStatusColor, JOB_STATUS_COMPLETE } from "@easybiz/utils";
import { PhotoGallery } from "@easybiz/web-component";
import { Alert, Card, Space, Spin, Tag } from "antd";
import dayjs from "dayjs";
import React, { lazy, Suspense } from "react";
import JobContactUpdateButton from "../JobContactUpdateButton";
import JobHistoryButton from "../JobHistoryButton";
import JobScheduleButton from "../JobScheduleButton";
import JobStatusUpdateTag from "../JobStatusUpdateTag";

const AssignWorkerButton = lazy(() => import("../AssignWorkerButton"));

export default function JobStatusCard({ task, disabled, onStatus }) {
  const { address, district, schedule, status, type, note, photos, recurring } = task.data();
  const clientType = useClientType();
  const client = useClientType();

  return (
    <Card
      title={
        <b>
          {recurring
            ? `[${recurring.index + 1}/${recurring.total}] ${dayjs()
                .date(recurring.index + 1)
                .format("Do")} `
            : ""}
          <TaskTitle type={type} />
        </b>
      }
      extra={
        client === CLIENT_CONSOLE ? (
          <JobStatusUpdateTag
            taskId={task?.id}
            status={task?.get("status")}
            customerId={task?.get("customer.id")}
            orderId={task?.get("orderId")}
          />
        ) : (
          <Tag color={getJobStatusColor(status)} onClick={() => onStatus(task)}>
            <LogisticStatusDisplay status={status} />
          </Tag>
        )
      }
      actions={[
        <Space>
          {clientType === CLIENT_CONSOLE && (
            <JobScheduleButton
              scheduleType={task.get("type")}
              orderId={task.get("orderId")}
              taskId={task.id}
              businessCode={task.get("businessCode")}
              serviceId={task.get("serviceId")}
              customerId={task.get("customer.id")}
              select={{
                ...task.get("schedule"),
                address: task.get("address"),
                ...(task.get("option") && { option: task.get("option") }),
              }}
              disabled={disabled || task.get("sstatus") === JOB_STATUS_COMPLETE}
              title={false}
            />
          )}
          {clientType === CLIENT_CONSOLE && (
            <Suspense fallback={<Spin />}>
              <AssignWorkerButton task={task} disabled={disabled} />
            </Suspense>
          )}
          <JobContactUpdateButton task={task} disabled={disabled} />
          <JobHistoryButton taskId={task?.id} />
        </Space>,
      ]}
    >
      <Card.Meta
        title={<DateDisplay {...schedule} weekday="long" />}
        description={
          <Space direction="vertical" className="full-width">
            <span>
              {district && <Tag>{district.id}</Tag>}
              <AddressDisplay address={address} />
            </span>
            {note && <Alert banner type="warning" message={note} />}
            {photos && <PhotoGallery photos={photos} />}
          </Space>
        }
      />
    </Card>
  );
}
