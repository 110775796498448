import { NameDisplay, ProcedureDisplay } from "@easybiz/component";
import { useBusinessCode } from "@easybiz/context/src/ConfigurationContext";
import { useSetOpenOrderId } from "@easybiz/context/src/LiveStateContext";
import { ACTION_STORE_IN, ACTION_STORE_OUT, PROCEDURE_STORE_CHECK_IN, PROCEDURE_STORE_CHECK_OUT } from "@easybiz/utils";
import { ReceiptView } from "@easybiz/web-admin";
import { useOrderStateQuery } from "@easybiz/web-firebase";
import { Drawer, List, Tabs } from "antd";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import HandoverHistoryDrawer from "./HandoverHistoryDrawer";
import HandoverPanel from "./HandoverPanel";
import PanelHeader from "./PanelHeader";

export default ({ isCheckOut, onClose }) => {
  const [historyVisible, setHistoryVisible] = useState(false);
  const [receiptLiens, setReceiptLines] = useState();
  const businessCode = useBusinessCode();
  const setOpenOrderId = useSetOpenOrderId();
  const [orders] = useOrderStateQuery(
    isCheckOut
      ? { awaitAction: ACTION_STORE_OUT, businessCode, limit: 200 }
      : { awaitAction: ACTION_STORE_IN, collectionPlace: businessCode, limit: 200 }
  );

  let orderServices = orders?.map((order) => order.get("service.id")) || [];
  orderServices = orderServices.filter((id, index) => orderServices.indexOf(id) === index);

  return (
    <div className="flex flex-fill scroll-container">
      <PanelHeader
        title={
          isCheckOut ? (
            <ProcedureDisplay procedure={PROCEDURE_STORE_CHECK_OUT} processing />
          ) : (
            <ProcedureDisplay procedure={PROCEDURE_STORE_CHECK_IN} />
          )
        }
        onClose={onClose}
      />
      {orders ? (
        <Tabs tabBarStyle={{ margin: 0 }} type="card">
          {orderServices.map((serviceId) => {
            const serviceOrders = orders?.filter((order) => order.get("service.id") === serviceId) || [];

            return (
              <Tabs.TabPane
                key={serviceId}
                tab={
                  <span>
                    <NameDisplay nameKey={serviceId} /> ({serviceOrders?.length || 0})
                  </span>
                }
              >
                <HandoverPanel
                  serviceId={serviceId}
                  orders={serviceOrders}
                  isCheckOut={isCheckOut}
                  onComplete={setReceiptLines}
                  onSelect={(order) => setOpenOrderId(order.id)}
                  onHistory={() => setHistoryVisible(true)}
                />
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      ) : (
        <List loading dataSource={[]} />
      )}
      <HandoverHistoryDrawer isCheckOut={isCheckOut} open={historyVisible} onClose={() => setHistoryVisible(false)} />
      <Drawer
        width={470}
        placement="right"
        title={<FormattedMessage tagName="span" defaultMessage="Handover Receipt" />}
        open={Boolean(receiptLiens)}
        onClose={() => setReceiptLines(null)}
      >
        {receiptLiens && <ReceiptView lines={receiptLiens} printing />}
      </Drawer>
    </div>
  );
};
